import React, {Fragment, useEffect, useState} from "react";
import Modal from "../../common/components/Modal";
import {formatAmount, formatDate} from "../../common/utils";
import SelectBox from "../../common/components/SelectBox";
import * as API from "../../common/api";

const BookingModal = ({entry, onClose}) => {

    const [linkedObjects, setLinkedObjects] = useState({})
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [selectedLinkedObject1, setSelectedLinkedObject1] = useState()
    const [selectedLinkedObject2, setSelectedLinkedObject2] = useState()

    const objectTypes = {
        PROPERTY: 'Objekt',
        RENTAL_AGREEMENT: 'Mietvertrag',
        LOAN: 'Darlehen',
        NONE: 'Ohne Bezug'
    }

    const filterRentalAgreements = (o1, o2) => (!o1) || (o2.propertyId === o1.id)

    const templates = [
        { name: 'Miete/Nebenkosten', linkedObjectType1: 'PROPERTY', linkedObjectType2: 'RENTAL_AGREEMENT', filter: filterRentalAgreements },
        { name: 'Miete',             linkedObjectType1: 'PROPERTY', linkedObjectType2: 'RENTAL_AGREEMENT', filter: filterRentalAgreements },
        { name: 'Nebenkosten',       linkedObjectType1: 'PROPERTY', linkedObjectType2: 'RENTAL_AGREEMENT', filter: filterRentalAgreements },
        { name: 'Kaution',           linkedObjectType1: 'PROPERTY', linkedObjectType2: 'RENTAL_AGREEMENT', filter: filterRentalAgreements },
        { name: 'Hausgeld',          linkedObjectType1: 'PROPERTY' },
        { name: 'Verwaltungskosten', linkedObjectType1: 'PROPERTY' },
        { name: 'Versicherung',      linkedObjectType1: 'PROPERTY' },
        { name: 'Grundsteuer',       linkedObjectType1: 'PROPERTY' },
        { name: 'Investition',       linkedObjectType1: 'PROPERTY' },
        { name: 'Kaufpreis',         linkedObjectType1: 'PROPERTY' },
        { name: 'Kaufnebenkosten',   linkedObjectType1: 'PROPERTY' },
        { name: 'Darlehen',          linkedObjectType1: 'LOAN' },
        { name: 'Andere',            linkedObjectType1: 'NONE' },
        { name: 'Unbekannt',         linkedObjectType1: 'NONE' },
    ]

    useEffect(() => {
        fetchLinkedObjects()
    }, [])

    const fetchLinkedObjects = () => API.fetchLinkedObjects().then(response => setLinkedObjects(response.data))

    const formatLinkedObjectLabel = (type, obj) => {
        if (type === 'PROPERTY') {
            return obj.id + ' - ' + obj.plz + ' ' + obj.stadt + ', ' + obj.strasseUndHausnr + (obj.seNr ? ', ' + obj.seNr : '')
        } else if (type === 'RENTAL_AGREEMENT') {
            return obj.id + ' - ' + obj.counterParty
        } else if (type === 'LOAN') {
            return obj.id + ' - ' + obj.name
        } else {
            return '-';
        }
    }

    const selectLinkedObject2 = (obj) => {
        if (obj && selectedTemplate.linkedObjectType2 === 'RENTAL_AGREEMENT') {
            const properties = linkedObjects['PROPERTY'].filter(o => o.id === obj.propertyId);
            if (properties && properties.length > 0) {
                setSelectedLinkedObject1(properties[0])
            }
        }
        setSelectedLinkedObject2(obj)
    }

    const canSubmit = () => {
        if (selectedTemplate) {
            if (selectedTemplate.linkedObjectType1 === 'NONE') {
                return true
            }
            if (selectedTemplate.linkedObjectType2) {
                return selectedLinkedObject1 && selectedLinkedObject2
            } else {
                return selectedLinkedObject1
            }
        }
        return false
    }

    const bookTransactions = () => {

        const clearingInstruction = {
            templateName: selectedTemplate.name,
            ledgerEntryId: entry.id,
            linkedObjectType1: selectedTemplate.linkedObjectType1,
            linkedObjectId1: selectedLinkedObject1 ? selectedLinkedObject1.id : null,
            linkedObjectType2: selectedTemplate.linkedObjectType2,
            linkedObjectId2: selectedLinkedObject2 ? selectedLinkedObject2.id : null
        }

        return API.clearBankTransaction(clearingInstruction)
    }

    return (

        <Modal title={`Umsatz buchen`}
               closeLabel="Schliessen"
               submitLabel={canSubmit() ? "Buchen" : null}
               onClose={onClose}
               onSubmit={bookTransactions}
               classNames="modal-md">

            <div className="transaction-container">

                <div className="form-group">
                    <label>Vorlage</label>
                    <SelectBox placeholder="Vorlage..."
                               clearable={true}
                               autoWidth={false}
                               maxMenuHeight={200}
                               values={templates}
                               selectedValue={selectedTemplate}
                               valueMapper={t => ({value: t, label: t.name})}
                               onChange={setSelectedTemplate}/>
                </div>

                {selectedTemplate && selectedTemplate.linkedObjectType1 !== 'NONE' &&
                    <Fragment>

                        <div className="form-group">
                            <label>{objectTypes[selectedTemplate.linkedObjectType1]}</label>
                            <SelectBox placeholder={objectTypes[selectedTemplate.linkedObjectType1]}
                                       clearable={true}
                                       autoWidth={false}
                                       maxMenuHeight={200}
                                       values={linkedObjects[selectedTemplate.linkedObjectType1]}
                                       selectedValue={selectedLinkedObject1}
                                       valueMapper={lo => ({value: lo, label: formatLinkedObjectLabel(selectedTemplate.linkedObjectType1, lo)})}
                                       onChange={setSelectedLinkedObject1}/>
                        </div>

                        {selectedTemplate.linkedObjectType2 &&
                            <div className="form-group">
                                <label>{objectTypes[selectedTemplate.linkedObjectType2]}</label>
                                <SelectBox placeholder={objectTypes[selectedTemplate.linkedObjectType2]}
                                           clearable={true}
                                           autoWidth={false}
                                           maxMenuHeight={200}
                                           values={linkedObjects[selectedTemplate.linkedObjectType2].filter(o => selectedTemplate.filter(selectedLinkedObject1, o))}
                                           selectedValue={selectedLinkedObject2}
                                           valueMapper={lo => ({value: lo, label: formatLinkedObjectLabel(selectedTemplate.linkedObjectType2, lo)})}
                                           onChange={selectLinkedObject2}/>
                            </div>
                        }
                    </Fragment>
                }

                <table className="table table-condensed">
                    <tbody>
                    <tr className="active">
                        <th className="text-center" colSpan={2}>Umsatzdetails</th>
                    </tr>
                    <tr>
                        <th>Betrag</th>
                        <td className={entry.amount > 0 ? 'text-red' : ''} width="100%">{formatAmount(-entry.amount)}</td>
                    </tr>
                    <tr>
                        <th>Referenz</th>
                        <td>{entry.description}</td>
                    </tr>
                    <tr>
                        <th>Wertstellungsdatum</th>
                        <td>{formatDate(entry.valueDate, 'DD.MM.YYYY')}</td>
                    </tr>
                    <tr>
                        <th>Importiert</th>
                        <td>{formatDate(entry.createTime)}</td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </Modal>
    )
}

export default BookingModal