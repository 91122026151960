import React, {useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroller';
import BankTransactionList from "./BankTransactionList";
import BankTransactionFilter from "./BankTransactionFilter";
import {formatDateAsIso, groupRowsByKey} from "../../common/utils";
import BankTransactionDetailsModal from "./BankTransactionDetailsModal";
import * as API from "../../common/api";

const BankTransactionContainer = () => {

    const DEFAULT_BANK_TRANSACTIONS = {
        transactionsByDate: [],
        loadedSize: 0,
        totalSize: 0,
        debitAmount: 0,
        creditAmount: 0
    }

    const PAGE_SIZE = 50

    const [bankTransactions, setBankTransactions] = useState(DEFAULT_BANK_TRANSACTIONS)

    const [selectedBankTransaction, setSelectedBankTransaction] = useState(false)

    const [filter, setFilter] = useState({})

    useEffect(() => {
        onNextPage(0)
    }, [filter])

    const onNextPage = (pageIndex) => {
        const { bankAccount, fromDate, toDate } = filter
        if (bankAccount && (pageIndex === 0 || (pageIndex * PAGE_SIZE) < bankTransactions.totalSize)) {
            API.fetchBankTransactionsByValueDate(
                bankAccount.id,
                fromDate ? formatDateAsIso(fromDate) : null,
                toDate ? formatDateAsIso(toDate) : null,
                PAGE_SIZE,
                pageIndex
            ).then(response => {
                const page = response.data
                if (page.rows.length === 0) {
                    setBankTransactions(DEFAULT_BANK_TRANSACTIONS);
                } else {
                    setBankTransactions({
                        transactionsByDate: groupRowsByKey(page.index === 0 ? [] : [...bankTransactions.transactionsByDate], page.rows, bt => new Date(bt.valueDate).getTime()),
                        loadedSize: (page.index * page.size) + page.rows.length,
                        totalSize: page.metadata.totalSize,
                        debitAmount: page.metadata.debitAmount,
                        creditAmount: page.metadata.creditAmount,
                    })
                }
            })
        }
    }

    const showBankTransactionDetailsModal = (bankTransaction) => {
        setSelectedBankTransaction(bankTransaction)
    }

    const hideBankTransactionDetailsModal = () => {
        setSelectedBankTransaction(null)
    }

    const { transactionsByDate, loadedSize, totalSize, debitAmount, creditAmount } = bankTransactions

    return (
        <div>

            <BankTransactionFilter onFilterChanged={setFilter}/>

            <InfiniteScroll
                pageStart={0}
                loadMore={onNextPage}
                hasMore={loadedSize < totalSize}
                loader={<div className="loader" key={0}>Loading ...</div>}>
                <BankTransactionList bankTransactionsByDate={transactionsByDate}
                                     debitAmount={debitAmount}
                                     creditAmount={creditAmount}
                                     onTransactionSelected={showBankTransactionDetailsModal}/>
            </InfiniteScroll>

            {selectedBankTransaction &&
                <BankTransactionDetailsModal bankTransaction={selectedBankTransaction}
                                             onClose={hideBankTransactionDetailsModal}/>
            }
        </div>
    )
}

export default BankTransactionContainer

