import React, {useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroller';
import {formatDate, groupRowsByKey} from "../common/utils";
import * as API from "../common/api";
import Activity from "./Activity";


const ActivityContainer = () => {

    const [state, setState] = useState({activitiesByDate: [], loadedSize: 0, totalSize: 0})

    const { activitiesByDate, loadedSize, totalSize } = state

    const pageSize = 100;

    useEffect(() => {
        onNextPage(0)
    }, [])

    const activityDate = (activity) => {
        const date = new Date(activity.createTime);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    }

    const fetchActivities = (pageIndex, pageSize) => {
        return API.fetchActivities(pageIndex, pageSize).then(response => {
            const page = response.data
            if (page.rows.length > 0) {
                const rowsByDate = groupRowsByKey(page.index === 0 ? [] : [...activitiesByDate], page.rows, activityDate);
                const newState = {
                    activitiesByDate: rowsByDate,
                    loadedSize: (page.index * page.size) + page.rows.length,
                    totalSize: page.metadata.totalSize
                }
                setState(newState)
            }
        })
    }

    const onNextPage = (pageIndex) => {
        if (pageIndex === 0 || (pageIndex * pageSize) < totalSize) {
            fetchActivities(pageIndex, pageSize);
        }
    }

    return (
        <div>
            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li>Aktivitäten ({loadedSize} / {totalSize})</li>
                </ol>
            </div>
            <InfiniteScroll
                pageStart={0}
                loadMore={onNextPage}
                hasMore={loadedSize < totalSize}
                loader={<div className="loader" key={0}>Loading ...</div>}>
                <div>
                    {activitiesByDate.map((dateAndActivities, idx) => (
                        <table key={`activity-group-${idx}`} className="activity-table table table-hover" style={{marginBottom: "5px", fontSize: "12px"}}>
                            <thead>
                            <tr>
                                <th className="text-left text-primary" style={{width: "50px"}}>{formatDate(parseInt(dateAndActivities[0]), 'DD.MM.YYYY')}</th>
                                <th className="ruler">
                                    <div/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {dateAndActivities[1].map(activity => <Activity key={`activity-${activity.id}`} activity={activity}/>)}
                            </tbody>
                        </table>
                    ))}

                </div>
            </InfiniteScroll>
        </div>
    )
}

export default ActivityContainer

