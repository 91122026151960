import React from "react";

const ActionLink = ({onClick, children, style= {}, stopPropagation= false}) => {

	const click = (event) => {
		event.preventDefault()
		if (stopPropagation) {
			event.stopPropagation()
		}
		onClick && onClick()
	};

	return (
		<a href="" style={style} onClick={click}>{children}</a>
	)
}

export default ActionLink
