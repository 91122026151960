import React, {useState} from "react";
import {formatAmount, formatDate} from "../common/utils";
import NavLink from "../common/components/NavLink";

const RentingReport = ({rows}) => {

    const [showExpired, setShowExpired] = useState(false)

    const toggleShowExpired = () => {
        setShowExpired(!showExpired)
    }

    const renderRow = (prevRow, row) => {
        const renderProperty = !prevRow || prevRow.propertyId !== row.propertyId;

        return (
            <tr key={`rr-${row.id}`} className={!renderProperty ? 'expired-rental-agreement' : ''}>
                <td className="text-center">
                    {renderProperty &&
                    <NavLink path={`/properties/${row.propertyId}`}>{row.propertyId}</NavLink>
                    }
                </td>
                <td className="text-left">{renderProperty && row.stadt}</td>
                <td className="text-left">{renderProperty && row.strasseUndHausnr}</td>
                <td className="text-left">{row.mieter || 'Unbekannt'}</td>
                <td className="text-center border-left">{row.vermietetVon ? formatDate(row.vermietetVon, 'DD.MM.YYYY') : '-'}</td>
                <td className="text-center">{row.vermietetBis ? formatDate(row.vermietetBis, 'DD.MM.YYYY') : '∞'}</td>
                <td className="text-right border-left">{formatAmount(row.kaltmiete, '0,0.00')}</td>
                <td className="text-right">{formatAmount(row.kaltmieteIst, '0,0.00')}</td>
                <td className="text-right">{formatAmount(row.kaltmieteSoll, '0,0.00')}</td>
                <td className={`text-right ${Math.round(row.kaltmieteDiff) < 0 && 'text-red'}`}>{formatAmount(row.kaltmieteDiff, '0,0.00')}</td>
                <td className="text-right border-left">{formatAmount(row.kaution, '0,0.00')}</td>
                <td className="text-right">{formatAmount(row.kautionIst, '0,0.00')}</td>
                <td className="text-right">{formatAmount(row.kautionSoll, '0,0.00')}</td>
                <td className={`text-right ${Math.round(row.kautionDiff) < 0 && 'text-red'}`}>{formatAmount(row.kautionDiff, '0,0.00')}</td>
            </tr>
        );
    };

    const sortedRows = rows.sort((r1, r2) => {
        let i = r1.propertyId - r2.propertyId;
        if (i === 0) {
            i = new Date(r2.vermietetVon) - new Date(r1.vermietetVon);
        }
        return i;
    })

    const filteredRows = sortedRows.filter(row => showExpired || !row.expired);

    return (
        <div>

            <button className="btn btn-xs btn-link pull-right" style={{padding: "10px 0"}} onClick={toggleShowExpired} onMouseDown={e => e.preventDefault()}>
                {showExpired ? 'Abgelaufene Mietverträge ausblenden' : 'Abgelaufene Mietverträge einblenden'}
            </button>

            <table className="table table-hover renting-report">
                <thead>
                <tr>
                    <th className="text-center" colSpan={6}/>
                    <th className="text-center" colSpan={4}>Kaltmiete</th>
                    <th className="text-center" colSpan={4}>Kaution</th>
                </tr>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-left">Stadt</th>
                    <th className="text-left">Adresse</th>
                    <th className="text-left">Mieter</th>
                    <th className="text-center border-left">Von</th>
                    <th className="text-center">Bis</th>
                    <th className="text-right border-left">Kaltmiete</th>
                    <th className="text-right">Ist</th>
                    <th className="text-right">Soll</th>
                    <th className="text-right">Bilanz</th>
                    <th className="text-right border-left">Kaution</th>
                    <th className="text-right">Ist</th>
                    <th className="text-right">Soll</th>
                    <th className="text-right">Bilanz</th>
                </tr>
                </thead>
                <tbody>
                {filteredRows.map((row, idx) => renderRow(idx > 0 ? rows[idx - 1] : null, row))}
                </tbody>
            </table>
        </div>
    )
}

export default RentingReport
