import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as API from "../common/api";
import ActionLink from "../common/components/ActionLink";
import {formatDate} from "../common/utils";
import CommentForm from "./CommentForm";
import PropertyChangesModal from "../common/components/PropertyChangesModal";

const Activity = ({activity, onChange, activityClassName}) => {

    const [editable, setEditable] = useState(false)
    const [showChangesModal, setShowChangesModal] = useState(false)

    const { t } = useTranslation('changes')

    const isComment = activity.type === 'COMMENT_CREATED' || activity.type === 'COMMENT_UPDATED';

    const toggleEditable = () => {
        setEditable(!editable)
    }

    const onShowChangesModal = () => {
        setShowChangesModal(true)
    }

    const onCloseChangesModal = () => {
        setShowChangesModal(false)
    }

    const updateComment = (comment) => {
        return API.updateComment(activity.commentId, comment).then(onChange)
    }

    const deleteComment = () => {
        return API.deleteComment(activity.commentId).then(onChange)
    }

    const renderChanges = (activity) => {
        return (
            <div>
                <div className="spacer5"/>
                <ul>
                    {activity.changes.length > 0 && activity.changes.map(c =>
                        <li key={activity.id + '-' + c.name}><strong>{t(`changes:${activity.type}.${c.name}`)}</strong></li>
                    )}
                </ul>
                <ActionLink onClick={onShowChangesModal}>Details</ActionLink>
            </div>
        )
    }

    return (

        <div className={activityClassName}>

            <div className="activity-heading">
                <span className="text-primary">{activity.userName}</span><span className="text-muted"> am {formatDate(activity.createTime)}</span>
                {isComment &&
                <div className="pull-right hidden-print">
                    <ActionLink onClick={toggleEditable} style={{marginRight: "5px"}}>
                        <i className={editable ? 'glyphicon glyphicon-remove' : 'glyphicon glyphicon-edit'}/>
                    </ActionLink>
                    <ActionLink onClick={deleteComment}>
                        <i className="glyphicon glyphicon-trash"/>
                    </ActionLink>
                </div>
                }
            </div>

            {isComment && editable &&
            <CommentForm style={{margin: "10px 10px"}} initValue={activity.text} onSubmit={updateComment}/>
            }

            {isComment && !editable &&
            <div className="activity-body">
                {activity.text}
            </div>
            }

            {activity.type === 'PROPERTY_UPDATED' &&
            <div className="activity-body">
                Objekt wurde bearbeitet
                { renderChanges(activity) }
            </div>
            }

            {activity.type === 'RENTAL_AGREEMENT_CREATED' &&
            <div className="activity-body">
                Mietvertrag <strong>#{activity.rentalAgreementId} ({activity.text})</strong> wurde angelegt
            </div>
            }

            {activity.type === 'RENTAL_AGREEMENT_UPDATED' &&
            <div className="activity-body">
                Mietvertrag <strong>#{activity.rentalAgreementId} ({activity.text})</strong> wurde bearbeitet
                { renderChanges(activity) }
            </div>
            }

            {activity.type === 'RENTAL_AGREEMENT_DELETED' &&
            <div className="activity-body">
                Mietvertrag <strong>#{activity.rentalAgreementId} ({activity.text})</strong> wurde gelöscht
            </div>
            }

            {activity.type === 'CONTACT_CREATED' &&
            <div className="activity-body">
                Kontakt <strong>#{activity.contactId} ({activity.text})</strong> wurde angelegt
            </div>
            }

            {activity.type === 'CONTACT_UPDATED' &&
            <div className="activity-body">
                Kontakt <strong>#{activity.contactId} ({activity.text})</strong> wurde bearbeitet
                { renderChanges(activity) }
            </div>
            }

            {activity.type === 'CONTACT_DELETED' &&
            <div className="activity-body">
                Kontakt <strong>#{activity.contactId} ({activity.text})</strong> wurde gelöscht
            </div>
            }

            {activity.type === 'ATTACHMENT_CREATED' &&
            <div className="activity-body">
                Anhang <strong>{activity.text}</strong> wurde hinzugefügt
            </div>
            }

            {activity.type === 'ATTACHMENT_UPDATED' &&
            <div className="activity-body">
                Anhang <strong>{activity.text}</strong> wurde bearbeitet
                { renderChanges(activity) }
            </div>
            }

            {activity.type === 'ATTACHMENT_DELETED' &&
            <div className="activity-body">
                Anhang <strong>{activity.text}</strong> wurde gelöscht
            </div>
            }

            {activity.type === 'LIKE_UPDATED' &&
            <div className="activity-body">
                <i className={activity.text === 'true' ? 'glyphicon glyphicon-thumbs-up' : 'glyphicon glyphicon-thumbs-down'}/>
            </div>
            }

            {showChangesModal &&
            <PropertyChangesModal activity={activity}
                                  onClose={onCloseChangesModal}/>
            }

        </div>
    )
}

const ActivityPanel = ({activities, onChange}) => {

    return (
        <div className="panel panel-default" style={{marginTop: "25px"}}>
            {activities.map((a, idx) =>
                <Activity
                    key={a.id}
                    activity={a}
                    activityClassName={idx === 0 ? 'activity' : 'activity activity-n'}
                    onChange={onChange}/>)
            }
        </div>
    )
}

export default ActivityPanel


