import React from "react";

const FormGroup = ({label, children}) => (
	<div className="form-group">
		<label className="col-md-2 control-label">{label}</label>
		<div className="col-sm-10">
			{children}
		</div>
	</div>
);

export default FormGroup