import React from "react";
import {formatAmount, formatDate} from "../../common/utils";

const BankTransactionList = ({bankTransactionsByDate, debitAmount, creditAmount, onTransactionSelected}) => {

    const balance = debitAmount + creditAmount;

    const renderDateRow = (date) => (
        <tr className="transaction-date-header">
            <th className="text-left text-primary" style={{width: "50px"}}>{formatDate(parseInt(date), 'DD.MM.YYYY')}</th>
            <th className="ruler" colSpan="4" >
                <div/>
            </th>
        </tr>
    )

    const renderRow = (bt) => (
        <tr key={`bt-${bt.id}`} className="transaction-row" style={{fontSize: "12px"}}>
            <td className="text-right">
                {bt.id}
            </td>
            <td className="text-left text-muted" style={{cursor: "pointer"}} onClick={() => onTransactionSelected(bt)}>
                {bt.reference}
            </td>
            <td className="text-right" style={{width: "70px"}}>
                {bt.amount >= 0 && <span>{formatAmount(bt.amount)}</span>}
            </td>
            <td className="text-right text-red" style={{width: "70px"}}>
                {bt.amount < 0 && <span>{formatAmount(bt.amount)}</span>}
            </td>
        </tr>
    )

    return (
        <table className="transaction-table table table-hover" style={{marginBottom: "5px"}}>
            <thead style={{fontSize: "12px"}}>
                <tr>
                    <th className="text-left">Bilanz</th>
                    <th/>
                    <th className="text-right" style={{width: "70px"}}>
                        {balance >= 0 && formatAmount(balance)}
                    </th>
                    <th className="text-right text-red" style={{width: "70px"}}>
                        {balance < 0 && formatAmount(balance)}
                    </th>
                </tr>
                <tr>
                    <th className="text-left">Umsatz</th>
                    <th className="text-left"/>
                    <th className="text-right" style={{width: "70px"}}>{formatAmount(creditAmount)}</th>
                    <th className="text-right text-red" style={{width: "70px"}}>{formatAmount(debitAmount)}</th>
                </tr>
            </thead>
            {bankTransactionsByDate.map((dateAndBankTransactions, idx) =>
                <tbody key={`dabt-${idx}`} style={{fontSize: "12px"}}>
                    {renderDateRow(dateAndBankTransactions[0])}
                    {dateAndBankTransactions[1].map(renderRow)}
                </tbody>
            )}
        </table>
    )
}

export default BankTransactionList