import React, {useState} from "react";
import Modal from "../common/components/Modal";
import {Menu, MenuItem, Typeahead} from 'react-bootstrap-typeahead';
import {propertyName} from "../common/image";
import ActionLink from "../common/components/ActionLink";

const ImageCollectionEditModal = ({title, collection, propertiesById, onClose, onSubmit}) => {

    const [name, setName] = useState((collection && collection.name) || '')
    const [description, setDescription] = useState((collection && collection.description) || '')
    const [propertyIds, setPropertyIds] = useState((collection && collection.propertyIds) || [])
    const [selectedProperties, setSelectedProperties] = useState([])

    const submit = () => onSubmit({
        id: (collection && collection.id) || null,
        name: name,
        description: description,
        coverImageId: (collection && collection.coverImageId) || 0,
        propertyIds: propertyIds
    })

    const addProperty = () => {
        const ids = Object.assign([], propertyIds)
        selectedProperties.forEach(prop => ids.push(prop.id))
        setSelectedProperties([])
        setPropertyIds(ids)
    }

    const removeProperty = (id) => setPropertyIds(Object.assign({}, propertyIds.filter(propertyId => propertyId !== id)))

    const renderTypeaheadMenu = (results, menuProps) => (
        <Menu {...menuProps} className="typeahead-menu">
            {results.map((result, index) =>
                <MenuItem option={result} position={index}>{result.label}</MenuItem>
            )}
        </Menu>
    )

    const propertyNames = Object.keys(propertiesById).map(id => ({id: id, label: propertyName(propertiesById[id])}))

    return (

        <Modal title={title}
               closeLabel="Schliessen"
               onClose={onClose}
               submitLabel="Speichern"
               onSubmit={submit}
               classNames="modal-lg">

            <form className="form">

                <div className="form-group">
                    <label>Name</label>
                    <input type="text"
                           className="form-control"
                           value={name || ''}
                           onChange={e => setName(e.target.value)}/>
                </div>

                <div className="form-group">
                    <label>Objekte</label>
                    <div className="input-group">
                        <Typeahead
                            multiple={false}
                            selected={selectedProperties}
                            options={propertyNames}
                            placeholder="+ Objekt hinzufügen"
                            onChange={setSelectedProperties}
                            renderMenu={renderTypeaheadMenu}/>
                        <span className="input-group-btn">
                            <button className="btn btn-default" type="button" onClick={addProperty}>+</button>
                        </span>
                    </div>

                    <div className="spacer spacer5"/>

                    <ul className="list-inline unstyled">
                        {propertyIds.filter(id => propertiesById[id]).map(id => (
                            <li>
                                <span className="tag label label-primary">
                                    {propertyName(propertiesById[id])}
                                    <ActionLink style={{color: "white"}} onClick={() => removeProperty(id)}>
                                        <span style={{fontSize: "15px", marginLeft: "8px"}}>&times;</span>
                                    </ActionLink>
                                </span>
                            </li>
                        ))}
                    </ul>

                </div>

                <div className="form-group">
                    <label htmlFor="description">Beschreibung</label>
                    <textarea className="form-control"
                              rows="5"
                              value={description || ''}
                              onChange={e => setDescription(e.target.value)}/>
                </div>

            </form>

        </Modal>
    )
}

export default ImageCollectionEditModal