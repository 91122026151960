import React from "react";
import {formatAmount, formatDate} from "../../common/utils";
import {startCase, toLower, replace} from "lodash";

const LedgerEntryList = ({entriesByDate, debitAmount, creditAmount, onEntrySelected}) => {

    const balance = debitAmount + creditAmount;

    const renderDateRow = (date) => (
        <tr className="transaction-date-header">
            <th className="text-left text-primary" style={{width: "50px"}}>{formatDate(parseInt(date), 'DD.MM.YYYY')}</th>
            <th className="ruler" colSpan="3" >
                <div/>
            </th>
        </tr>
    )

    const formatEntry = (entry) => {

        let text = formatAccount(entry.account)

        const description = entry.description || 'Keine Angabe'

        if (entry.linkedObjectType === 'BANK_TRANSACTION') {
            return description
        }

        if (entry.linkedObjectType === 'NONE') {
            return text + ' - ' + description
        }

        if (entry.linkedProperty) {
            text += ' - ' + formatProperty(entry.linkedProperty)
        }
        if (entry.linkedRentalAgreement) {
            text += ' - ' + formatRentalAgreement(entry.linkedRentalAgreement)
        }
        if (entry.linkedLoan) {
            text += ' - ' + formatLoan(entry.linkedLoan)
        }

        return text
    }

    const formatAccount = (a) => startCase(toLower(replace(a, '_', ' ')))

    const formatProperty = (p) => p.plz + ' ' + p.stadt + ', ' + p.strasseUndHausnr + ' (' + p.id + ')'

    const formatRentalAgreement = (a) => a.counterParty + ' (' + a.id + ')'

    const formatLoan = (l) => l.name + ' (' + l.id + ')'

    const renderRow = (entry) => (
        <tr key={`bt-${entry.id}`} className="transaction-row" style={{fontSize: "12px"}}>
            <td className="text-right">
                {entry.id}
            </td>
            <td className="text-left text-muted" style={{cursor: "pointer"}} onClick={() => onEntrySelected(entry)}>
                {formatEntry(entry)}
            </td>
            <td className="text-right" style={{width: "70px"}}>
                {entry.amount <= 0 && <span>{formatAmount(-entry.amount)}</span>}
            </td>
            <td className="text-right text-red" style={{width: "70px"}}>
                {entry.amount >  0 && <span>{formatAmount(-entry.amount)}</span>}
            </td>
        </tr>
    )

    return (
        <table className="transaction-table table table-hover" style={{marginBottom: "5px"}}>
            <thead style={{fontSize: "12px"}}>
                <tr>
                    <th className="text-left">Bilanz</th>
                    <th/>
                    <th className="text-right" style={{width: "70px"}}>
                        {balance <= 0 && formatAmount(-balance)}
                    </th>
                    <th className="text-right text-red" style={{width: "70px"}}>
                        {balance > 0 && formatAmount(-balance)}
                    </th>
                </tr>
                <tr>
                    <th className="text-left">Umsatz</th>
                    <th/>
                    <th className="text-right" style={{width: "70px"}}>{formatAmount(-debitAmount)}</th>
                    <th className="text-right text-red" style={{width: "70px"}}>{formatAmount(-creditAmount)}</th>
                </tr>
            </thead>
            {entriesByDate.map((dateAndEntries, idx) =>
                <tbody key={`dabt-${idx}`} style={{fontSize: "12px"}}>
                    {renderDateRow(dateAndEntries[0])}
                    {dateAndEntries[1].map(renderRow)}
                </tbody>
            )}
        </table>
    )

}

export default LedgerEntryList