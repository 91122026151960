import React, {useEffect, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import AnnualsReport from "./AnnualsReport";
import PropertyReport from "./PropertyReport";
import LoanReport from "./LoanReport";
import {useHistory} from "react-router";
import * as API from "../common/api";
import useCurrentUser from "../shell/useCurrentUser";
import RentingReport from "./RentingReport";
import ActionLink from "../common/components/ActionLink";
import {googleUser} from "../common/utils";

const ReportContainer = ({name}) => {

    const [rows, setRows] = useState([])

    const { permissions } = useCurrentUser()

    const history = useHistory()

    useEffect(() => {
        setRows([])
        fetchReport()
    }, [name])

    const fetchReport = () => {
        return API.fetchReport(name).then(response => setRows(response.data))
    }

    const onSelectReport = (key) => {
        setRows([])
        history.push(`/reports/${key}`)
    }

    const onExport = () => {
        const user = googleUser();
        user.reloadAuthResponse();
        user.grant({scope: 'https://www.googleapis.com/auth/drive'});
        API.exportReport(name, user.getAuthResponse().access_token).then(response => window.open(response.data, '_blank'))
    };

    return (
        <div>

            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li>Berichte</li>
                </ol>
                <ul className="nav nav-pills pull-right">
                    <li>
                        <ActionLink onClick={onExport}><i className="glyphicon glyphicon-export"/><label>Google Sheets</label></ActionLink>
                    </li>
                </ul>
            </div>

            <div className="report-container">
                <Tabs id="reports" activeKey={name} onSelect={onSelectReport}>
                    <Tab eventKey="jahreskennzahlen" title="Jahreskennzahlen">
                        {name === 'jahreskennzahlen' &&
                            <AnnualsReport rows={rows}/>
                        }
                    </Tab>
                    <Tab eventKey="bestand" title="Bestand">
                        {name === 'bestand' &&
                            <PropertyReport rows={rows}/>
                        }
                    </Tab>
                    <Tab eventKey="vermietung" title="Vermietung">
                        {name === 'vermietung' &&
                        <RentingReport rows={rows}/>
                        }
                    </Tab>
                    {permissions.accountant &&
                        <Tab eventKey="darlehen" title="Darlehen">
                            {name === 'darlehen' &&
                            <LoanReport rows={rows}/>
                            }
                        </Tab>
                    }
                </Tabs>
            </div>
        </div>
    )
}


export default ReportContainer;
