import React, {useEffect, useState} from "react";
import * as _ from "underscore";
import TransactionDateRangePicker from "../../common/components/TransactionDateRangePicker";
import SelectBox from "../../common/components/SelectBox";
import * as API from "../../common/api";
import {replace, startCase, toLower} from "lodash";

const LedgerEntryFilter = ({onFilterChanged}) => {

    const objectTypes = {
        PROPERTY: 'Objekt',
        LOAN: 'Darlehen',
        NONE: 'Ohne Bezug'
    }

    const [accounts, setAccounts] = useState([])
    const [linkedObjects, setLinkedObjects] = useState({})
    const [selectedAccount, setSelectedAccount] = useState()
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const [selectedObjectType, setSelectedObjectType] = useState()
    const [selectedObjectId, setSelectedObjectId] = useState()

    useEffect(() => {
        fetchAccounts()
        fetchLinkedObjects()
    }, [])

    const fetchAccounts = () => API.fetchAccounts().then(response => setAccounts(response.data))

    const fetchLinkedObjects = () => API.fetchLinkedObjects().then(response => setLinkedObjects(response.data))

    const refresh = () => onFilterChanged({
        account: selectedAccount,
        linkedObjectType: selectedObjectType,
        linkedObjectId: selectedObjectId,
        fromDate: selectedDateRange ? _.first(selectedDateRange) : null,
        toDate: selectedDateRange ? _.last(selectedDateRange) : null
    })

    const formatAccount = (a) => startCase(toLower(replace(a, '_', ' ')))

    const selectObjectType = (objectType) => {
        if (objectType !== selectedObjectType) {
        	setSelectedObjectType(objectType)
	        setSelectedObjectId(null)
        }
    }

    const formatLinkedObjectLabel = (type, obj) => {
        if (type === 'PROPERTY') {
            return obj.id + ' - ' + obj.plz + ' ' + obj.stadt + ', ' + obj.strasseUndHausnr + (obj.seNr ? ', ' + obj.seNr : '')
        } else if (type === 'LOAN') {
            return obj.id + ' - ' + obj.name
        } else {
            return '-';
        }
    }

    const selectedLinkedObjects = (selectedObjectType && linkedObjects[selectedObjectType]) || [];

    return (

        <div className="transaction-criteria ledger-entry-selector">

            <SelectBox placeholder="Konto..."
                       clearable={true}
                       values={accounts}
                       selectedValue={selectedAccount}
                       valueMapper={account => ({value: account, label: formatAccount(account)})}
                       onChange={setSelectedAccount}/>

            <TransactionDateRangePicker value={selectedDateRange}
                                        onChange={setSelectedDateRange}/>

            <SelectBox placeholder="Bezug..."
                       clearable={true}
                       values={_.keys(objectTypes)}
                       selectedValue={selectedObjectType}
                       valueMapper={objectType => ({value: objectType, label: objectTypes[objectType]})}
                       onChange={selectObjectType}/>

            {selectedLinkedObjects.length > 0 &&
                <SelectBox placeholder={objectTypes[selectedObjectType] + "..."}
                           clearable={true}
                           values={selectedLinkedObjects}
                           selectedValue={selectedObjectId}
                           valueMapper={linkedObject => ({value: linkedObject.id, label: formatLinkedObjectLabel(selectedObjectType, linkedObject)})}
                           onChange={setSelectedObjectId}/>
            }

            <div className="btn-group">
                <button type="button" className="btn btn-default" onClick={refresh}>
                    <span className="glyphicon glyphicon-refresh"/>
                </button>
            </div>

        </div>
    )
}

export default LedgerEntryFilter