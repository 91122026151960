import React, {useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroller';
import LedgerEntryList from "./LedgerEntryList";
import LedgerEntryFilter from "./LedgerEntryFilter";
import {formatDateAsIso, groupRowsByKey} from "../../common/utils";
import {useHistory} from "react-router";
import * as API from "../../common/api";

const LedgerEntryContainer = () => {

    const DEFAULT_LEDGER_ENTRIES = {
        entriesByDate: [],
        loadedSize: 0,
        totalSize: 0,
        debitAmount: 0,
        creditAmount: 0
    }

    const PAGE_SIZE = 50

    const [ledgerEntries, setLedgerEntries] = useState(DEFAULT_LEDGER_ENTRIES)

    const [filter, setFilter] = useState({})

    const history = useHistory()

    useEffect(() => {
        onNextPage(0)
    }, [filter])

    const onNextPage = (pageIndex) => {

        const { account, linkedObjectType, linkedObjectId, fromDate, toDate } = filter


        if (pageIndex === 0 || (pageIndex * PAGE_SIZE) < ledgerEntries.totalSize) {

            API.fetchLedgerEntries(
                account,
                linkedObjectType,
                linkedObjectId,
                fromDate ? formatDateAsIso(fromDate) : null,
                toDate ? formatDateAsIso(toDate) : null,
                PAGE_SIZE,
                pageIndex
            ).then(response => {
                const page = response.data
                if (page.rows.length === 0) {
                    setLedgerEntries(DEFAULT_LEDGER_ENTRIES)
                } else {
                    setLedgerEntries({
                        entriesByDate: groupRowsByKey(page.index === 0 ? [] : [...ledgerEntries.entriesByDate], page.rows, le => new Date(le.valueDate).getTime()),
                        loadedSize: (page.index * page.size) + page.rows.length,
                        totalSize: page.metadata.totalSize,
                        debitAmount: page.metadata.debitAmount,
                        creditAmount: page.metadata.creditAmount,
                    })
                }
            })
        }
    }

    const selectEntry = (entry) => {
        // history.push(`/accounting/ledger-entries/${entry.transactionId}`)
    }

    const { entriesByDate, loadedSize, totalSize, debitAmount, creditAmount } = ledgerEntries;

    return (
        <div>

            <LedgerEntryFilter onFilterChanged={setFilter}/>

            <InfiniteScroll pageStart={0}
                            loadMore={onNextPage}
                            hasMore={loadedSize < totalSize}
                            loader={<div className="loader" key={0}>Loading ...</div>}>
                <LedgerEntryList entriesByDate={entriesByDate}
                                 debitAmount={debitAmount}
                                 creditAmount={creditAmount}
                                 onEntrySelected={selectEntry}/>
            </InfiniteScroll>
        </div>
    )
}

export default LedgerEntryContainer

