import React, {useEffect, useState} from "react";
import {formatAmount, formatDate} from "../common/utils";
import NavLink from "../common/components/NavLink";
import {sortBy} from "underscore";

const PropertyReport = ({rows}) => {

    const [sortedRows, setSortedRows] = useState(rows)
    const [sortKey, setSortKey] = useState('id')
    const [sortDirection, setSortDirection] = useState(1)

    useEffect(() => {
        sort(sortKey, sortDirection)
    }, [rows])

    const sort = (sortKey, sortDirection) => {
        let sorted = sortBy(rows, sortKey);
        if (sortDirection < 0) {
            sorted = sorted.reverse()
        }
        setSortedRows(sorted)
        setSortKey(sortKey)
        setSortDirection(sortDirection)
    }

    const renderSortIcon = (key) => {
        if (key !== sortKey) {
            return <i className="fa fa-unsorted ml-1" style={{cursor: "pointer", marginLeft: "2px"}} onClick={() => sort(key, 1)}/>
        } else if (sortDirection >= 0) {
            return <i className="fa fa-caret-up ml-1" style={{cursor: "pointer", marginLeft: "2px"}} onClick={() => sort(key, -1)}/>
        } else {
            return <i className="fa fa-caret-down ml-1" style={{cursor: "pointer", marginLeft: "2px"}} onClick={() => sort(key, 1)}/>
        }
    }

    const renderRow = (row) => (
        <tr key={`pr-${row.id}`}>
            <td className="text-center"><NavLink path={`/properties/${row.id}`}>{row.id}</NavLink></td>
            <td className="text-left">{row.stadt}</td>
            <td className="text-left">{row.addresse}</td>
            <td className="text-center">{row.nutzenLastenVon && formatDate(row.nutzenLastenVon, 'DD.MM.YYYY')}</td>
            <td className="text-right">{formatAmount(row.wohnflaeche, '0,0')}</td>
            {row.vermietet ? (
                <td className="text-center">Ja</td>
            ) : (
                <td className="text-center text-red">Nein</td>
            )}
            <td className="text-center">
                <div className="amortisation">
                    <div className="progress">
                        <div className="progress-bar" style={{width: row.amortisationsStatus * 100 + '%'}}/>
                    </div>
                    <div className="percentage">{formatAmount(row.amortisationsStatus * 100, '0,0')}%</div>
                </div>
            </td>
            <td className={`text-right ${row.rendite < 6 && 'text-red'}`}>{formatAmount(row.rendite, '0,0.0')}</td>
            <td className="text-right">{formatAmount(row.einnahmen, '0,0')}</td>
            <td className="text-right">{formatAmount(row.ausgaben, '0,0')}</td>
            <td className="text-right">{formatAmount(row.kaufpreis, '0,0')}</td>
            <td className="text-right">{formatAmount(row.kaufpreisProQm, '0,0')}</td>
            <td className="text-right">{formatAmount(row.investitionen, '0,0')}</td>
        </tr>
    )

    return (
        <table className="table table-hover property-report">
            <thead>
            <tr>
                <th className="text-center text-nowrap"># {renderSortIcon('id')}</th>
                <th className="text-left text-nowrap">Stadt {renderSortIcon('stadt')}</th>
                <th className="text-left text-nowrap">Adresse {renderSortIcon('addresse')}</th>
                <th className="text-center text-nowrap">Nutzen / Lasten  {renderSortIcon('nutzenLastenVon')}</th>
                <th className="text-right text-nowrap">m² {renderSortIcon('wohnflaeche')}</th>
                <th className="text-center text-nowrap">Vermietet {renderSortIcon('vermietet')}</th>
                <th className="text-center text-nowrap">Amortisationsstatus {renderSortIcon('amortisationsStatus')}</th>
                <th className="text-right text-nowrap">Rendite {renderSortIcon('rendite')}</th>
                <th className="text-right text-nowrap">Einnahmen {renderSortIcon('einnahmen')}</th>
                <th className="text-right text-nowrap">Ausgaben {renderSortIcon('ausgaben')}</th>
                <th className="text-right text-nowrap">Kaufpreis {renderSortIcon('kaufpreis')}</th>
                <th className="text-right text-nowrap">KP/m² {renderSortIcon('kaufpreisProQm')}</th>
                <th className="text-right text-nowrap">Investitionen {renderSortIcon('investitionen')}</th>
            </tr>
            </thead>
            <tbody>
                {sortedRows.map(renderRow)}
            </tbody>
        </table>
    )
}

export default PropertyReport
