import React, {Fragment, useEffect, useState} from "react";
import Modal from "../common/components/Modal";
import {groupRowsByKey} from "../common/utils";
import PropertyTile from "./PropertyTile";
import * as _ from "underscore";
import * as API from "../common/api";
import ActionLink from "../common/components/ActionLink";
import {useHistory} from "react-router";
import {capitalize} from "lodash";

const PropertyGroupContainer = ({group}) => {

    const [properties, setProperties] = useState([])
    const [confirmCreatePropertyModalVisible, setConfirmCreatePropertyModalVisible] = useState(false)

    const history = useHistory()

    useEffect(() => {
        fetchProperties()
    }, [group])

    const id = (p) => p.fields.stadt ? p.fields.stadt + p.fields.strasseUndHausnr : 'Unbekannter Ort' + p.id

    const fetchProperties = () => API.fetchProperties(group.toUpperCase()).then(response => setProperties(_.sortBy(response.data, id)))

    const showConfirmCreatePropertyModal = () => setConfirmCreatePropertyModalVisible(true)

    const hideConfirmCreatePropertyModal = () => setConfirmCreatePropertyModalVisible(false)

    const createProperty = () => API.createProperty().then(response => history.push(`/properties/${response.data.id}`))

    const groupPropertiesByAddress = () => groupRowsByKey([], properties, id)

    return (
        <Fragment>

            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li>{capitalize(group)} ({properties.length} {(properties.length === 1 ? 'Objekt' : 'Objekte')})</li>
                </ol>
                <ul className="nav nav-pills pull-right">
                    <li>
                        <ActionLink onClick={showConfirmCreatePropertyModal}><i className="glyphicon glyphicon-plus"/><label>Neues Objekt</label></ActionLink>
                    </li>
                </ul>
            </div>



            <div className="row">
                <div className="col-md-12">
                    <ul className="property-list">
                        {groupPropertiesByAddress().map(pair =>
                            <PropertyTile key={`pt-${pair[1][0].id}`}
                                          properties={pair[1]}/>
                        )}
                    </ul>
                </div>
            </div>

            {confirmCreatePropertyModalVisible &&
                <Modal title="Bestätigung"
                       closeLabel="Abbrechen"
                       onClose={hideConfirmCreatePropertyModal}
                       submitLabel="Erstellen"
                       onSubmit={createProperty}
                       classNames="modal-md">
                    Neues Objekt erstellen?
                </Modal>
            }
        </Fragment>
    )
}

export default PropertyGroupContainer

