import React from "react";
import {formatAmount} from "../common/utils";

const AnnualsReport = ({rows}) => {

    const renderRow = (row) => (
        <tr key={`ar-${row.year}`}>
            <td className="text-center">{row.year}</td>
            <td className="text-right">{row.einheiten}</td>
            <td className="text-right">{formatAmount(row.wohnflaeche, '0,0')}</td>
            <td className="text-right">{formatAmount(row.leerstandAmJahresende * 100, '0,0')}</td>
            <td className="text-right">{formatAmount(row.leerstand * 100, '0,0')}</td>
            <td className="text-right">{formatAmount(row.gewinn, '0,0')}</td>
            <td className="text-right">{formatAmount(row.zinsen, '0,0')}</td>
            <td className="text-right">{formatAmount(row.darlehen, '0,0')}</td>
            <td className="text-right">{formatAmount(row.kaltmiete, '0,0')}</td>
            <td className="text-right">{formatAmount(row.betriebskosten, '0,0')}</td>
            <td className="text-right">{formatAmount(row.kaufpreis, '0,0')}</td>
            <td className="text-right">{formatAmount(row.kaufnebenkosten, '0,0')}</td>
            <td className="text-right">{formatAmount(row.investitionen, '0,0')}</td>
            <td className="text-right">{formatAmount(row.investitionenProQm, '0,0.00')}</td>
        </tr>
    )

    return (
        <table className="table table-condensed table-hover annuals-report">
            <thead>
            <tr>
                <th className="text-center">Jahr</th>
                <th className="text-right">Einheiten</th>
                <th className="text-right">Fläche (m²)</th>
                <th className="text-right">Leerstand (%)</th>
                <th className="text-right">LKZ (%)</th>
                <th className="text-right">Ergebnis</th>
                <th className="text-right">Zinsen</th>
                <th className="text-right">Darlehen</th>
                <th className="text-right">Mieteinnahmen</th>
                <th className="text-right">Betriebskosten</th>
                <th className="text-right">Kaufpreise</th>
                <th className="text-right">Kauf-NK</th>
                <th className="text-right">Investitionen</th>
                <th className="text-right">Investitionen/m²</th>
            </tr>
            </thead>
            <tbody>
            {rows.map(renderRow)}
            </tbody>
        </table>
    )
}

export default AnnualsReport
