import React from "react";
import 'bootstrap-social/bootstrap-social.css'
import Select from "react-select";
import * as _ from "underscore";

const SelectBox = ({placeholder, clearable, values, valueMapper, selectedValue, size, onChange, disabled, autoWidth = true, maxMenuHeight}) => {

    const styles = {
        md: {
            customStyles: {
                control: provided => ({...provided, minHeight: "34px", borderRadius: "2px"}),
                indicatorsContainer: provided => ({...provided, height: "32px"}),
                clearIndicator: provided => ({...provided, padding: "5px"}),
                dropdownIndicator: provided => ({...provided, padding: "5px"})
            },
            font: "14px sans-serif"
        },
        sm : {
            customStyles: {
                control: provided => ({...provided, minHeight: "28px", borderRadius: "2px"}),
                indicatorsContainer: provided => ({...provided, height: "26px"}),
                indicatorSeparator: provided => ({...provided, marginTop: "4px", marginBottom: "4px",}),
                clearIndicator: provided => ({...provided, padding: "2px"}),
                dropdownIndicator: provided => ({...provided, padding: "2px"}),
                input: provided => ({...provided, margin: "0"}),
                valueContainer: provided => ({...provided, paddingTop: "0", paddingBottom: "0"}),
            },
            font: "11px sans-serif"
        }
    }

    const selectedStyle = size ? styles[size] : styles.md;

    const options = values.map(valueMapper);

    let canvas;

    const measureTextWidth = (text) => {
        if (!canvas) {
            canvas = document.createElement("canvas");
        }
        const context = canvas.getContext("2d");
        context.font = selectedStyle.font;
        const metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    }

    const selectWidth = (options) => {
        return (_.reduce(options.map(o => o.label), (memo, label) => Math.max(memo, measureTextWidth(label)), 0) + 90) + 'px';
    }

    const findSelectedOption = (options) => {
        return selectedValue ? _.find(options, opt => opt.value === selectedValue) : null;
    }

    return (
        <div style={autoWidth ? {width: selectWidth(options)} : {}}>
            <Select placeholder={placeholder}
                    options={options}
                    value={findSelectedOption(options)}
                    isDisabled={disabled}
                    isClearable={clearable}
                    styles={selectedStyle.customStyles}
                    maxMenuHeight={maxMenuHeight || 300}
                    onChange={option => onChange(option ? option.value : null)}/>
        </div>
    )
}

export default SelectBox
