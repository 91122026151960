import React, {useState} from "react";
import {useHistory} from "react-router";

const PropertyTile = ({properties}) => {

    const [index, setIndex] = useState(0)

    const history = useHistory()

    const length = properties.length;
    const showLeftArrow = index > 0;
    const showRightArrow = index < length - 1;

    const property = properties[index];

    const { stadt, strasseUndHausnr, lageImGebaeude, seNr } = property.fields;

    const onPropertySelected = (property) => {
        history.push(`/properties/${property.id}`)
    }

    const renderImage = (property) => (
        <img src={`/api/images/${property.imageCollectionId}/cover?image-set=GALLERY`} alt="" onClick={() => onPropertySelected(property)}/>
    )

    const renderPlaceholder = (property) => (
        <div className="property-tile-image-placeholder" onClick={() => onPropertySelected(property)}>
            <i className="glyphicon glyphicon-picture"/>
        </div>
    )

    const onPrevProperty = () => {
        setIndex(index - 1)
    }

    const onNextProperty = () => {
        setIndex(index + 1)
    }

    const subFooterText = () => {
        let subFooter = lageImGebaeude;
        if (subFooter && subFooter.length > 0) {
            subFooter += ", ";
        }
        return subFooter + seNr;
    }

    return (
        <li className="property-tile-panel">

            <div className="property-tile-id">
                {property.id}
            </div>

            <div className="property-tile-image">
                {property.imageCollectionId > 0 ? renderImage(property) : renderPlaceholder(property)}
            </div>

            <div className="property-tile-footer">
                <div>
                    <div className="cover-footer-address">
                        {stadt}, {strasseUndHausnr} {length > 1 && (`- ${index + 1}/${length}`)}
                        <div style={{fontSize: "8px"}}>{subFooterText() || 'Keine Angabe'}</div>
                    </div>
                </div>
            </div>

            {showLeftArrow &&
                <div className="property-tile-arrow property-tile-arrow-left" onClick={onPrevProperty}>
                    <i className="halflings halflings-menu-left"/>
                </div>
            }
            {showRightArrow &&
                <div className="property-tile-arrow property-tile-arrow-right" onClick={onNextProperty}>
                    <i className="halflings halflings-menu-right"/>
                </div>
            }
        </li>
    )

}

export default PropertyTile