import React, {useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useHistory} from "react-router";
import LedgerEntryContainer from "./ledger/LedgerEntryContainer";
import BankTransactionContainer from "./banktransaction/BankTransactionContainer";
import UnaccountedLedgerEntryContainer from "./ledger/UnaccountedLedgerEntryContainer";
import BankStatementUploadModal from "./banktransaction/BankStatementUploadModal";

const AccountingContainer = ({tab}) => {

    const [bankTransactionUploadModalVisible, setBankTransactionUploadModalVisible] = useState(false)

    const history = useHistory()

    const showBankTransactionUploadModal = () => {
        setBankTransactionUploadModalVisible(true)
    }

    const hideBankTransactionUploadModal = () => {
        setTimeout(() => { setBankTransactionUploadModalVisible(false) }, 250)
    }

    const showNewTransactionModal = () => {
        history.push(`/accounting/${tab}/new`)
    }

    const selectTab = (tab) => {
        history.push(`/accounting/${tab}`)
    }

    const getTabTitle = (tab) => {
        switch (tab) {
            case 'ledger-entries':
                return 'Buchungsumsätze';
            case 'bank-transactions':
                return 'Bankumsätze';
            case 'balances':
                return 'Kontostände'
            default:
                return 'Zu buchende Umsätze';
        }
    }

    return (

        <div>

            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li>Buchhaltung</li>
                    <li>{getTabTitle(tab)}</li>
                </ol>
                <ul className="nav nav-pills pull-right">
                    {tab === 'bank-transactions' &&
                    <li>
                        <a onClick={showBankTransactionUploadModal}>
                            <i className="glyphicon glyphicon-cloud-upload"/><label>Kontauszug laden</label>
                        </a>
                    </li>
                    }
                    {tab !== 'bank-transactions' &&
                    <li>
                        <a onClick={showNewTransactionModal}>
                            <i className="glyphicon glyphicon-plus"/><label>Neue Buchung</label>
                        </a>
                    </li>
                    }
                </ul>
            </div>

            <div className="report-container">
                <Tabs id="reports" activeKey={tab} onSelect={selectTab}>
                    <Tab eventKey="unaccounted-entries" title={getTabTitle('unaccounted-ledger-entries')}>
                        <UnaccountedLedgerEntryContainer/>
                    </Tab>
                    <Tab eventKey="ledger-entries" title={getTabTitle('ledger-entries')}>
                        <LedgerEntryContainer/>
                    </Tab>
                    <Tab eventKey="bank-transactions" title={getTabTitle('bank-transactions')}>
                        <BankTransactionContainer bankTransactionUploadModalVisible={bankTransactionUploadModalVisible}/>
                    </Tab>
                </Tabs>
            </div>

            {bankTransactionUploadModalVisible &&
                <BankStatementUploadModal onClose={hideBankTransactionUploadModal}/>
            }

        </div>
    )
}

export default AccountingContainer
