import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {de} from './locales/de/default'

const resources = {
    de: de
};

i18n
    .use(initReactI18next)
    .init({
        resources: resources,
        debug: true,
        lng: 'de',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;