import React, {useEffect, useState} from "react";
import {formatDate} from "../common/utils";
import ThumbnailGallery from "../common/components/ThumbnailGallery";
import ActionLink from "../common/components/ActionLink";
import * as API from "../common/api";
import useCurrentUser from "../shell/useCurrentUser";

const ImagePanel = ({propertyId, coverCollectionId, onUpdateCoverCollectionId}) => {

	const [imageCollections, setImageCollections] = useState([])
	const [imageDescriptors, setImageDescriptors] = useState([])

	const { permissions } = useCurrentUser()

	useEffect(() => {
		fetchCollections()
		fetchDescriptors()
	}, [propertyId])

	const fetchCollections = () => {
		return API.fetchImageCollections(propertyId).then(response => setImageCollections(response.data))
	}

	const fetchDescriptors = () => {
		return API.fetchImageDescriptorsByPropertyId(propertyId).then(response => setImageDescriptors(response.data))
	}

	return (
		<div className="panel panel-default">
			<div className="panel-body">
				{imageDescriptors.length > 0 && imageCollections.map((col, idx) => {
					const descriptors = imageDescriptors.filter(d => d.collectionId === col.id);
					return (
						<div key={`image-collection-${col.id}`} className="row">
							<div className="col-xs-12">
								{idx > 0 &&
									<hr style={{margin: "10px 0"}}/>
								}
								<div className="pull-left" style={{margin: "2px"}}>
									<strong>{col.id === coverCollectionId ? 'Cover-Album' : 'Album'}:</strong>&nbsp;<a href={`/images/${col.id}`}>{col.name}</a>
								</div>
								{descriptors &&
									<ThumbnailGallery descriptors={descriptors}
									                  editable={false}
									                  downloadEnabled={false}
									                  scalingFactor={0.5}/>
								}
								<div className="imgcol-footer">
									<small className="text-muted">{descriptors.length} {descriptors.length === 1 ? 'Bild' : 'Bilder'} - Erstellt am {formatDate(col.createTime)} von {col.createUser}</small>
									{permissions.editor && coverCollectionId !== col.id &&
										<small className="text-muted">- <ActionLink onClick={() => onUpdateCoverCollectionId(col.id)}>Als Cover verwenden</ActionLink></small>
									}
								</div>
							</div>
						</div>
					)}
				)}
			</div>
		</div>
	)
}

export default ImagePanel