import React, {Fragment, useEffect, useState} from "react";
import {formatDate} from "../common/utils";
import Modal from "../common/components/Modal";
import useCurrentUser from "../shell/useCurrentUser";
import ActionLink from "../common/components/ActionLink";
import * as API from "../common/api";
import FormGroup from "../common/components/FormGroup";

const RentalAgreementEditModal = ({rentalAgreement, onClose, onSubmit}) => {

    const [fields, setFields] = useState(rentalAgreement || {})

    const updateField = (field, update) => {
        const updatedFields = Object.assign({}, fields)
        updatedFields[field] = update
        setFields(updatedFields)
    }

    const submit = () => onSubmit(fields)

    return (
        <Modal title="Mietvertrag bearbeiten"
               closeLabel="Schliessen"
               onClose={onClose}
               submitLabel="Speichern"
               onSubmit={submit}
               classNames="modal-lg">

            <form className="form-horizontal">

                <FormGroup label="Mieter">
                    <input type="text"
                           className="form-control"
                           value={fields.counterParty || ''}
                           onChange={e => updateField('counterParty', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Adresse">
                    <input type="text"
                           className="form-control"
                           value={fields.counterPartyAddress || ''}
                           onChange={e => updateField('counterPartyAddress', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Von">
                    <input type="date"
                           className="form-control"
                           value={fields.startDate || ''}
                           onChange={e => updateField('startDate', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Bis">
                    <input type="date"
                           className="form-control"
                           value={fields.endDate || ''}
                           onChange={e => updateField('endDate', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Kaltmiete">
                    <div className="input-group">
                        <input type="number"
                               className="form-control"
                               value={fields.rent || 0}
                               onChange={e => updateField('rent', e.target.value)}/>
                        <span className="input-group-addon">€</span>
                    </div>
                </FormGroup>

                <FormGroup label="Kaution">
                    <div className="input-group">
                        <input type="number"
                               className="form-control"
                               value={fields.rentDeposit || 0}
                               onChange={e => updateField('rentDeposit', e.target.value)}/>
                        <span className="input-group-addon">€</span>
                    </div>
                </FormGroup>

            </form>

        </Modal>
    )
}

const RentalAgreement = ({rentalAgreement, onChange}) => {

    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const { permissions } = useCurrentUser()

    const updateRentalAgreement = (update) => API.updateRentalAgreement(update).then(onChange)

    const deleteRentalAgreement = () => API.deleteRentalAgreement(rentalAgreement.id).then(onChange)

    const showEditModal = () => setEditModalVisible(true)

    const hideEditModal = () => setEditModalVisible(false)

    const showDeleteModal = () => setDeleteModalVisible(true)

    const hideDeleteModal = () => setDeleteModalVisible(false)

    return (
        <Fragment key={`ra-${rentalAgreement.id}`}>
            <tr>
                <td className="text-left" style={{paddingBottom: "0px"}}>
                    {rentalAgreement.id}
                </td>
                <td className="text-left" style={{paddingBottom: "0px"}}>
                    {formatDate(rentalAgreement.startDate, 'DD.MM.YY')} - {rentalAgreement.endDate ? formatDate(rentalAgreement.endDate, 'DD.MM.YY') : ''}
                </td>
                <td className="text-right" style={{paddingBottom: "0px"}}>
                    {rentalAgreement.rent}
                </td>
                <td className="text-right" style={{paddingBottom: "0px"}}>
                    {rentalAgreement.rentDeposit}
                </td>
                {permissions.editor &&
                    <td className="text-center" style={{paddingBottom: "1px"}} width="15%">
                        <ActionLink onClick={showEditModal}>
                            <i className="glyphicon glyphicon-edit mr-5"/>
                        </ActionLink>
                        <ActionLink onClick={showDeleteModal}>
                            <i className="glyphicon glyphicon-trash ml-5"/>
                        </ActionLink>
                    </td>
                }
            </tr>
            <tr>
                <td className="text-left" style={{border: "0", paddingTop: "0px"}} colSpan="3">
                    <span className="text-muted small">{rentalAgreement.counterParty}</span>
                </td>
                <td style={{border: "0", paddingTop: "0px"}}>
                    {editModalVisible &&
                        <RentalAgreementEditModal rentalAgreement={rentalAgreement}
                                                  onClose={hideEditModal}
                                                  onSubmit={updateRentalAgreement}/>
                        }
                    {deleteModalVisible &&
                        <Modal title="Mietvertrag löschen"
                               closeLabel="Abbrechen"
                               onClose={hideDeleteModal}
                               submitLabel="Löschen"
                               onSubmit={deleteRentalAgreement}
                               classNames="modal-md">
                            Mietvertrag #{rentalAgreement.id} wirklich löschen?
                        </Modal>
                    }
                </td>
            </tr>
        </Fragment>
    );
}

const RentalAgreementPanel = ({propertyId, onChange}) => {

    const [rentalAgreements, setRentalAgreements] = useState([])
    const [createModalVisible, setCreateModalVisible] = useState(false)

    const { permissions } = useCurrentUser()

    useEffect(() => {
        fetchRentalAgreements()
    }, [propertyId])

    const fetchRentalAgreements = () => API.fetchRentalAgreements(propertyId).then(response => setRentalAgreements(response.data))

    const createRentalAgreement = (agreement) => API.createRentalAgreement(agreement).then(onRentalAgreementChanged)

    const showCreateModal = () => setCreateModalVisible(true)

    const hideCreateModal = () => setCreateModalVisible(false)

    const onRentalAgreementChanged = () => fetchRentalAgreements().then(onChange)

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <span className="glyphicon glyphicon-briefcase"/> Mietverträge
                <div className="pull-right">
                    {permissions.editor &&
                    <ActionLink onClick={showCreateModal}>
                        <i className="glyphicon glyphicon-plus"/> Mietvertrag hinzufügen
                    </ActionLink>
                    }
                </div>
            </div>
            <div className="panel-body">
                <table className="table table-condensed" style={{marginBottom: "5px"}}>

                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Laufzeit</th>
                        <th className="text-right">Miete</th>
                        <th className="text-right">Kaution</th>
                        <th/>
                    </tr>
                    </thead>

                    <tbody>

                    {rentalAgreements.map(ra => <RentalAgreement key={`key-${ra.id}`} rentalAgreement={ra} onChange={onRentalAgreementChanged}/>)}

                    </tbody>

                </table>
            </div>

            {createModalVisible &&
                <RentalAgreementEditModal rentalAgreement={{propertyId: propertyId}}
                                          onClose={hideCreateModal}
                                          onSubmit={createRentalAgreement}/>
            }

        </div>
    )
}

export default RentalAgreementPanel