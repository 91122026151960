import React, {Component} from "react";
import PropTypes from "prop-types";

export default class CrudTable extends Component {

    static propTypes = {
        rows: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired,
        onRowCreated: PropTypes.func.isRequired,
        onRowUpdated: PropTypes.func.isRequired,
        onRowDeleted: PropTypes.func.isRequired,
    };

    state = {
        rows: [],
        selectedRowIdx: -1,
        selectedRow: null,
        dirtyCells: {},
        selectionEnabled: true
    };

    componentDidMount() {
        this.setState({
            rows: [...this.props.rows],
            selectedRowIdx: -1,
            selectedRow: null,
            dirtyCells: {},
            selectionEnabled: true
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows !== this.props.rows) {
            this.componentDidMount();
        }
    }

    updateField(key, update) {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.selectedRow[key] = update;
            newState.dirtyCells[key] = newState.selectedRow[key] !== newState.rows[newState.selectedRowIdx][key];
            return newState;
        });
    }

    isDirty = (col, rowIdx) => {
        const { selectedRowIdx, dirtyCells } = this.state;
        return selectedRowIdx === rowIdx && dirtyCells[col.key]
    };

    selectRow = (row, idx, selected) => {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            if (selected) {
                newState.selectedRowIdx = idx;
                newState.selectedRow = Object.assign({}, newState.rows[idx]);
                newState.selectionEnabled = false;
            } else {
                if (newState.selectedRowIdx === idx) {
                    newState.selectedRowIdx = -1;
                    newState.selectedRow = null;
                }
            }
            return newState;
        });
    };

    isAnySelected = () => {
        return this.state.selectedRowIdx >= 0;
    };

    isSelected = (idx) => {
        return this.state.selectedRowIdx === idx;
    };

    isEditable = (col, rowIdx) => {
        return this.isSelected(rowIdx) && col.editable;
    };

    onAdd = (event) => {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            const newRow = { };
            newState.rows.push(newRow);
            newState.selectedRowIdx = newState.rows.length - 1;
            newState.selectedRow = newRow;
            newState.selectionEnabled = false;
            return newState;
        });
    };

    onSave = (event) => {
        if (this.state.rows.length > this.props.rows.length) {
            this.props.onRowCreated(this.state.selectedRow);
        } else {
            this.props.onRowUpdated(this.state.selectedRow);
        }
    };

    onCancel = (event) => {
        this.setState({
            selectedRowIdx: -1,
            selectedRow: null,
            dirtyCells: {},
            selectionEnabled: true,
            rows: [...this.props.rows]
        });
    };

    onDelete = (event) => {
        this.props.onRowDeleted(this.state.selectedRow);
    };

    renderActions = () => {
        const anySelected = this.isAnySelected();
        return (
            <tr>
                <th colSpan={this.props.columns.length + 1} className="crud-table-actions">
                    {!anySelected &&
                        <button type="button" className="btn btn-default btn-xs crud-table-action-button pull-right" onClick={this.onAdd}>
                            <span className="glyphicon glyphicon-plus" aria-hidden="true"/> Hinzufügen
                        </button>
                    }
                    {anySelected &&
                        <button type="button" className="btn btn-default btn-xs crud-table-action-button pull-right" onClick={this.onDelete}>
                            <span className="glyphicon glyphicon-trash" aria-hidden="true"/> Löschen
                        </button>
                    }
                    {anySelected &&
                        <button type="button" className="btn btn-default btn-xs crud-table-action-button pull-right" onClick={this.onCancel}>
                            <span className="glyphicon glyphicon-remove" aria-hidden="true"/> Verwerfen
                        </button>
                    }
                    {anySelected &&
                        <button type="button" className="btn btn-default btn-xs crud-table-action-button pull-right" onClick={this.onSave}>
                            <span className="glyphicon glyphicon-floppy-disk" aria-hidden="true"/> Speichern
                        </button>
                    }
                </th>
            </tr>
        )
    };

    renderHeader = () => {
        return (
            <tr>
                <th/>
                {this.props.columns.map(this.renderHeaderCell)}
            </tr>
        )
    };

    renderHeaderCell = (col) => {
        if (col.type === 'bool') {
            return <th key={`th-${col.key}`} className="text-center">{col.label}</th>;
        } else {
            return <th key={`th-${col.key}`} className="text-left" style={{paddingLeft: "11px"}}>{col.label}</th>;
        }
    };

    renderRow = (row, rowIdx) => {
        const { selectionEnabled } = this.state;
        return (
            <tr key={`tr-${rowIdx}`} className={this.isSelected(rowIdx) ? 'info' : ''}>
                <th className="test-center">
                    <input type="checkbox"
                           disabled={!selectionEnabled}
                           checked={this.isSelected(rowIdx)}
                           onChange={e => this.selectRow(row, rowIdx, e.target.checked)}/>
                </th>
                {this.props.columns.map(col => (
                    <td key={`td-${rowIdx}-${col.key}`} className={col.type === 'bool' ? 'text-center' : 'text-left'}>
                        { this.renderRowCell(col, row, rowIdx) }
                    </td>
                ))}
            </tr>
        )
    };

    renderRowCell = (col, row, rowIdx) => {
        switch (col.type) {
            case 'bool':
                return this.renderCheckBox(col, row, rowIdx);
            default:
                return this.renderInput(col, row, rowIdx);
        }
    };

    renderCheckBox = (col, row, rowIdx) => {
        const value = this.isEditable(col, rowIdx) ? this.state.selectedRow[col.key] : row[col.key];
        return (
            <input type="checkbox"
                   disabled={!this.isEditable(col, rowIdx)}
                   className={this.isDirty(col, rowIdx) ? 'inline-checkbox inline-input-dirty' : 'inline-checkbox'}
                   checked={value || false}
                   onChange={e => this.updateField(col.key, e.target.checked)}/>
        );
    };

    renderInput = (col, row, rowIdx) => {
        const value = this.isEditable(col, rowIdx) ? this.state.selectedRow[col.key] : row[col.key];
        if (col.editable) {
            return (
                <input type={col.type}
                       style={{backgroundColor: this.isSelected(rowIdx) ? 'white' : 'transparent'}}
                       spellCheck="false"
                       disabled={!this.isEditable(col, rowIdx)}
                       className={this.isDirty(col, rowIdx) ? 'inline-input inline-input-dirty' : 'inline-input'}
                       value={value || ''}
                       onChange={e => this.updateField(col.key, e.target.value)}/>
            )
        } else {
            return (
                <span className="inline-text">{value}</span>
            )
        }
    };

    render() {
        const { rows } = this.state;
        return (
            <table className="table table-hover">
                <thead>
                {this.renderActions()}
                {this.renderHeader()}
                </thead>
                <tbody>
                {rows.map(this.renderRow)}
                </tbody>
            </table>
        );
    }

}