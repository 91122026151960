import React, {useEffect, useState} from "react";
import ImageCollection from "./ImageCollection";
import ImageCollectionFilter from "./ImageCollectionFilter";
import ImageCollectionEditModal from "./ImageCollectionEditModal";
import {SORT_ORDER_AZ, SORT_ORDER_UNDEFINED, SORT_ORDER_ZA, sortAndFilterImageCollections} from "../common/image";
import * as API from "../common/api";
import ActionLink from "../common/components/ActionLink";
import useCurrentUser from "../shell/useCurrentUser";

const ImageCollectionContainer = ({collectionId}) => {

    const [collections, setCollections] = useState({unfilteredCollections: [], filteredCollections:[], filterText: '', sortOrder: SORT_ORDER_UNDEFINED})
    const [propertiesById, setPropertiesById] = useState({})
    const [showCollectionCreateModal, setShowCollectionCreateModal] = useState(false)

    const { unfilteredCollections, filteredCollections, filterText, sortOrder } = collections

    const { permissions } = useCurrentUser()

    useEffect(() => {
        fetchCollections()
        fetchProperties()
    }, [collectionId])

    const fetchCollections = () => {
        return API.fetchImageCollections().then(response => {
            const newCollections = response.data
            let newFilterText = filterText;
            if (newFilterText === '' && collectionId) {
                const col = newCollections.find(col => col.id === collectionId);
                if (col ) {
                    newFilterText = col.name;
                }
            }
            setCollections({
                unfilteredCollections: newCollections,
                filteredCollections: sortAndFilterImageCollections(newCollections, sortOrder, newFilterText),
                filterText: newFilterText,
                sortOrder: sortOrder
            })
        })
    }

    const fetchProperties = () => {
        return API.fetchProperties().then(response => {
            const propsById = response.data.reduce((acc, prop) => {
                acc[prop.id] = prop
                return acc
            }, {})
            setPropertiesById(propsById)
        })
    }

    const onFilterChanged = (filterText) => {
        setCollections({
            unfilteredCollections: unfilteredCollections,
            filteredCollections: sortAndFilterImageCollections(unfilteredCollections, sortOrder, filterText),
            filterText: filterText,
            sortOrder: sortOrder
        })
    }

    const onSortOrderChanged = () => {
        const newSortOrder = sortOrder === SORT_ORDER_AZ ? SORT_ORDER_ZA : SORT_ORDER_AZ
        setCollections({
            unfilteredCollections: unfilteredCollections,
            filteredCollections: sortAndFilterImageCollections(unfilteredCollections, newSortOrder, filterText),
            filterText: filterText,
            sortOrder: newSortOrder
        })
    }

    const onCreateCollection = (collection, onSuccess, onError) => {
        return API.createImageCollection(collection).then(() => {
            fetchCollections()
            onSuccess()
        }).catch(onError)
    }

    const onShowCollectionCreateModal = () => {
        setShowCollectionCreateModal(true)
    }

    const onCloseModals = () => {
        setShowCollectionCreateModal(false)
    }

    return (
        <div>
            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li>Bilder</li>
                </ol>
                <ul className="nav nav-pills pull-right">
                    <li>
                        <ActionLink onClick={onSortOrderChanged}>
                            {sortOrder === SORT_ORDER_AZ ? (
                                <i className="glyphicon glyphicon-sort-by-alphabet-alt"/>
                            ) : (
                                <i className="glyphicon glyphicon-sort-by-alphabet"/>
                            )}
                            <label>Sortieren</label>
                        </ActionLink>
                    </li>
                    {permissions.editor &&
                        <li>
                            <ActionLink onClick={onShowCollectionCreateModal}>
                                <i className="glyphicon glyphicon-plus"/><label>Neues Album</label>
                            </ActionLink>
                        </li>
                    }
                </ul>
            </div>
            <ImageCollectionFilter initialValue={collections.filterText}
                                   onChange={onFilterChanged}/>
            {filteredCollections.map(col =>
                <ImageCollection key={`image-collection-${col.id}`}
                                 collection={col}
                                 propertiesById={propertiesById}
                                 onChange={fetchCollections}/>
            )}
            {showCollectionCreateModal &&
                <ImageCollectionEditModal title="Neues Album anlegen"
                                          propertiesById={propertiesById}
                                          onClose={onCloseModals}
                                          onSubmit={onCreateCollection}/>
            }
        </div>
    )
}

export default ImageCollectionContainer

