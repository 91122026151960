import moment from "moment";
import * as numeral from "numeral";
import {groupBy, pairs} from "underscore";

export function googleUser() {
    return window.gapi.auth2.getAuthInstance().currentUser.get();
}

export function formatDate(date, format = 'DD.MM.YYYY HH:mm') {
    return moment(date).format(format)
}

export function formatDateAsIso(date) {
    return formatDate(date, 'YYYY-MM-DD')
}

export function formatAmount(amount, format = '0,0.00') {
    return numeral(amount).format(format)
}

export function noOp() {

}

export function groupRowsByKey(rowsByKey, rows, groupByKey) {
    const last = rowsByKey.length > 0 ? rowsByKey[rowsByKey.length - 1] : null;
    pairs(groupBy(rows, groupByKey)).forEach(pair => {
        const date = pair[0];
        const rows = pair[1];
        if (last && last[0] === date) {
            last[1].concat(rows);
        } else {
            rowsByKey.push(pair)
        }
    });
    return rowsByKey;
}
