import React, {Component} from "react";
import PropTypes from "prop-types";
import {imageRows} from "../image";

export default class ThumbnailGallery extends Component {

    static propTypes = {
        descriptors: PropTypes.array.isRequired,
        collection: PropTypes.object,
        editable: PropTypes.bool.isRequired,
        downloadEnabled: PropTypes.bool.isRequired,
        scalingFactor: PropTypes.number.isRequired,
        onUpdateCollection: PropTypes.func,
        onDeleteImage: PropTypes.func
    };

    state = {
        dimensions: null,
        showAll: false
    };

    componentDidMount() {
        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
                height: this.container.offsetHeight,
            },
        });
    }

    onUpdateCoverImageId = (event, collection, descriptor) => {
        event.preventDefault();
        this.props.onUpdateCollection(Object.assign({}, collection, { coverImageId: descriptor.id }));
    };

    onDeleteImage = (event, descriptor) => {
        event.preventDefault();
        this.props.onDeleteImage(descriptor);
    };

    onShowAll = (showAll) => {
        this.setState({showAll: showAll})
    };

    renderImage = (descriptor, showMore, showLess, count) => {

        const { collection, editable, downloadEnabled } = this.props;

        const imgUrl =  `/api/images/${descriptor.collectionId}/${descriptor.id}`;

        const divStyle = {
            width: `${descriptor.width}px`,
            height: `${descriptor.height}px`,
            overflow : "hidden"
        };

        const imgStyle = {
            width: `${descriptor.twidth}px`,
            height: `${descriptor.theight}px`,
            marginLeft : `${-descriptor.horizontalOffset}px`
        };

        return (

            <div key={`image-${descriptor.id}`} className="imgcol-thumbnail">

                <div style={divStyle}>
                    <a href={imgUrl + '?image-set=GALLERY'} data-lightbox={`collection-${descriptor.collectionId}`}>
                        <img src={imgUrl + '?image-set=THUMBNAIL'} alt={imgUrl} style={imgStyle}/>
                    </a>
                </div>


                {showMore &&
                    <div className="imgcol-thumbnail-show-all" onClick={() => this.onShowAll(true)}>
                        <span style={{fontSize: `${descriptor.height / 3}px`, lineHeight: '1'}}>+{count}</span>
                    </div>
                }

                {showLess &&
                    <div className="imgcol-thumbnail-show-all" onClick={() => this.onShowAll(false)}>
                        <span style={{fontSize: `${descriptor.height / 3}px`, lineHeight: '1'}}>-{count}</span>
                    </div>
                }

                <div className="imgcol-thumbnail-actions" >
                    {editable && collection && collection.coverImageId === descriptor.id &&
                    <span className="glyphicon glyphicon-star"/>
                    }
                    {editable && collection && collection.coverImageId !== descriptor.id &&
                    <a href="" onClick={e => this.onUpdateCoverImageId(e, collection, descriptor)}>
                        <span className="glyphicon glyphicon-star-empty"/>
                    </a>
                    }
                    {editable &&
                    <a href="" onClick={e => this.onDeleteImage(e, descriptor)}>
                        <span className="glyphicon glyphicon-trash"/>
                    </a>
                    }
                    {downloadEnabled &&
                    <a href={imgUrl + '?image-set=MASTER'} download={`${descriptor.id}.${descriptor.extension}`}>
                        <span className="glyphicon glyphicon-download-alt"/>
                    </a>
                    }
                </div>

            </div>
        );
    };

    render() {
        const { descriptors, scalingFactor } = this.props;
        const { dimensions, showAll } = this.state;
        const rows = dimensions ? imageRows(dimensions.width, descriptors, scalingFactor) : [];
        return (
            <div className="imgcol-thumbnails" ref={el => (this.container = el)}>
                {rows
                    .filter((row, rowIdx) => showAll || rowIdx === 0)
                    .map((row, rowIdx) =>
                        row.map((descriptor, colIdx) => {
                            const showMore = rows.length > 1 && !showAll && rowIdx === 0               && colIdx === row.length - 1;
                            const showLess = rows.length > 1 &&  showAll && rowIdx === rows.length - 1 && colIdx === row.length - 1;
                            const count = descriptors.length - rows[0].length;
                            return this.renderImage(descriptor, showMore, showLess, count)
                        })
                    )
                }
            </div>
     );
    }

}