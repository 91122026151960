import React, {useState} from "react";
import {formatDate} from "../common/utils";
import useCurrentUser from "../shell/useCurrentUser";

const HeaderRow = ({label}) => (
    <div className="row">
        <div className="col-sm-12">
            <div className="property-group-header"><strong>{label}</strong></div>
        </div>
    </div>
)

const SelectRow = (props) => (
    <div className="row">
        <div className="col-lg-5"><span className="text-muted">{props.label}</span></div>
        <div className="col-lg-7">
            <select className={props.propertyDirty ? 'inline-input inline-input-dirty' : 'inline-input'}
                    style={{marginLeft: "3px"}}
                    disabled={!props.editable}
                    value={props.value}
                    onChange={props.onChange}>
                {props.children}
            </select>
        </div>
    </div>
)

const InputRow = ({label, value, type, dirty, editable, onChange}) => (
    <div className="row">
        <div className="col-lg-5">
            <span className="text-muted"><nobr>{label}</nobr></span>
        </div>
        <div className="col-lg-7">
            <input type={type}
                   style={type === 'date' ? {marginLeft: "4px"} : {marginLeft: "5px"}}
                   spellCheck="false"
                   disabled={!editable}
                   className={dirty ? 'inline-input inline-input-dirty' : 'inline-input'}
                   value={value}
                   onChange={onChange}/>
        </div>
    </div>
)

const CheckboxRow = ({label, value, dirty, editable, onChange}) => (
    <div className="row">
        <div className="col-lg-5">
            <span className="text-muted"><nobr>{label}</nobr></span>
        </div>
        <div className="col-lg-7">
            <input type="checkbox"
                   style={{marginLeft: "9px", width: "auto"}}
                   spellCheck="false"
                   disabled={!editable}
                   className={dirty ? 'inline-input inline-input-dirty' : 'inline-input'}
                   checked={value}
                   onChange={onChange}/>
        </div>
    </div>
)

const TextAreaRow = ({label, value, dirty, editable, onChange}) => (
    <div className="row">
        <div className="col-sm-12">
            <div className="spacer5"/>
            <textarea className={dirty ? 'form-control inline-input-dirty' : 'form-control'}
                      disabled={!editable}
                      rows="7"
                      spellCheck="false"
                      placeholder={label}
                      value={value}
                      onChange={onChange}/>
        </div>
    </div>
)

const LikeRow = ({label, likes, dislikes, onUpdate}) => (
    <div className="row">
        <div className="col-lg-5"><span className="text-muted">{label}</span></div>
        <div className="col-lg-7">
            <div className="like">
                <i className="glyphicon glyphicon-thumbs-up like-thumb" onClick={() => onUpdate(true)}/><span className="like-counter">{likes}</span>
                <span style={{paddingLeft: "10px"}}/>
                <i className="glyphicon glyphicon-thumbs-down like-thumb" onClick={() => onUpdate(false)}/><span className="like-counter">{dislikes}</span>
            </div>
        </div>
    </div>
)

const TextRow = ({label, value}) => (
    <div className="row">
        <div className="col-lg-5"><span className="text-muted"><nobr>{label}</nobr></span></div>
        <div className="col-lg-7"><span className="inline-input">{value}</span></div>
    </div>
)

const PropertyPanel = ({property, likes, onFieldsUpdated, onLikeUpdated}) => {

    const [fields, setFields] = useState({})

    const { permissions } = useCurrentUser();

    const updateField = (field, update) => {
        const updatedFields = Object.assign({}, fields)
        if (property.fields[field] !== update) {
            updatedFields[field] = update
        } else {
            delete updatedFields[field]
        }
        setFields(updatedFields)
        onFieldsUpdated(updatedFields);
    }

    const updateLike = (value) => {
        if (permissions.voter) {
            onLikeUpdated(value)
        }
    }

    const getField = (name) => {
        return fields[name] || property.fields[name]
    }

    const renderIntInput = (label, field) => {
        return renderInput(label, field, "number", 0, e => updateField(field, parseInt(e.target.value)))
    }

    const renderFloatInput = (label, field) => {
        return renderInput(label, field, "number", 0, e => updateField(field, parseFloat(e.target.value)))
    }

    const renderDateInput = (label, field) => {
        return renderInput(label, field, "date", "", e => updateField(field, e.target.value))
    }

    const renderStringInput = (label, field) => {
        return renderInput(label, field, "text", "", e => updateField(field, e.target.value))
    }

    const renderInput = (label, field, type, defaultValue, onChange) => {
        const value = getField(field)
        return (
            <InputRow label={label}
                      value={value || defaultValue}
                      type={type}
                      dirty={value !== property.fields[field]}
                      editable={permissions.editor}
                      onChange={onChange}/>
        )
    }

    const renderBoolInput = (label, field) => {
        const value = getField(field)
        return (
            <CheckboxRow label={label}
                         value={value}
                         dirty={value !== property.fields[field]}
                         editable={permissions.editor}
                         onChange={e => updateField(field, e.target.checked)}/>
        );
    }

    const renderTextInput = (label, field) => {
        const value = getField(field)
        return (
            <TextAreaRow label={label}
                         value={value || ''}
                         dirty={value !== property.fields[field]}
                         editable={permissions.editor}
                         onChange={e => updateField(field, e.target.value)}/>
        )
    }

    return (

        <div className="panel panel-default" style={{padding: "15px"}}>

            <h4 style={{margin: "10px 3px 0px 0px"}}>{getField('plz')}&nbsp;{getField('stadt')},&nbsp;{getField('strasseUndHausnr')}</h4>

            <span>{getField('lageImGebaeude')}</span>

            <div className="property-columns">

                <HeaderRow label="Status"/>

                <SelectRow label="Gruppe:"
                           value={fields['group'] || property['group']}
                           dirty={fields.group !== property.group}
                           editable={permissions.editor}
                           onChange={e => updateField("group", e.target.value)}>
                    <option value="BESTAND">Bestand</option>
                    <option value="ANGEBOTE">Angebote</option>
                    <option value="ARCHIV">Archiv</option>
                    <option value="VERKAUFT">Verkauft</option>
                </SelectRow>

                <TextRow label="Eingestellt am:" value={formatDate(property.createTime)}/>

                <LikeRow label="Abstimmung:"
                         likes={likes.filter(like => like.value).length}
                         dislikes={likes.filter(like => !like.value).length}
                         onUpdate={updateLike}/>

                <HeaderRow label="Adresse"/>

                {renderStringInput("Stadt:", "stadt")}
                {renderStringInput("PlZ:", "plz")}
                {renderStringInput("Straße/Nr.:", "strasseUndHausnr")}
                {renderStringInput("Lage im Haus:", "lageImGebaeude")}
                {renderIntInput("Etage:", "etage")}
                {renderStringInput("SE:", "seNr")}

                {/*<a href={`https://maps.google.de/maps?q=${property.fields.latitude},${property.fields.longitude}`} target="_blank" rel="noopener noreferrer">*/}
                {/*    <i className="glyphicon glyphicon-map-marker"/><label>Karte</label>*/}
                {/*</a>*/}

                {/*<TextRow label="Längengrad" value={fields.longitude}/>*/}
                {/*<TextRow label="Breitengrad" value={fields.latitude}/>*/}

                <HeaderRow label="Kauf"/>

                {renderFloatInput("Kaufpreis:", "kaufpreis")}
                {renderFloatInput("Kaufnebenkosten:", "kaufnebenkosten")}
                {renderBoolInput("Vermietet bei Kauf:", "vermietet")}
                {renderDateInput("Nutzen/Lasten seit:", "nutzenLastenVon")}
                {renderDateInput("Nutzen/Lasten bis:", "nutzenLastenBis")}

                <HeaderRow label="Vermietung (Indikativ)"/>

                {renderFloatInput("Kaltmiete:", "kaltmiete")}
                {renderFloatInput("Nebenkosten:", "nebenkosten")}
                {renderFloatInput("Kaution:", "kaution")}

                <HeaderRow label="Hausgeld"/>

                {renderFloatInput("Hausgeld:", "hausgeld")}
                {renderFloatInput("Instandhaltungsrücklage:", "instandhaltungsruecklage")}

                <HeaderRow label="Objekt"/>

                <SelectRow label="Typ:"
                           value={getField('typ')}
                           dirty={getField('typ') !== property.fields.typ}
                           editable={permissions.editor}
                           onChange={e => updateField("typ", e.target.value)}>
                    <option value="WOHNUNG">Wohnung</option>
                    <option value="EINFAMILIENHAUS">Einfamilienhaus</option>
                    <option value="MEHRFAMILIENHAUS">Mehrfamilienhaus</option>
                </SelectRow>

                {renderIntInput("Anzahl Zimmer:", "anzahlZimmer")}
                {renderIntInput("Anzahl Balkone:", "anzahlBalkone")}
                {renderIntInput("Anzahl Garagen:", "anzahlGaragen")}
                {renderStringInput("Ausstattung:", "ausstattung")}
                {renderFloatInput("Wohnfläche (m²):", "wohnflaeche")}
                {renderFloatInput("Grundstücksfläche (m²):", "grundstuecksflaeche")}
                {renderFloatInput("Energieverbrauch (kWh/m²a):", "energieverbrauch")}
                {renderIntInput("Baujahr:", "baujahr")}
                {renderIntInput("Sanierungsjahr:", "sanierungsjahr")}
                {renderTextInput("Objektbeschreibung", "beschreibung")}

                <HeaderRow label="Weitere"/>

                {renderFloatInput("MEA (%):", "miteigentumsanteil")}
                {renderFloatInput("Verkehrswert:", "verkehrswert")}
                {renderDateInput("Verkehrswertsdatum:", "verkehrswertdatum")}
                {renderStringInput("GBA:", "gbaNr")}
                {renderStringInput("Gebäudeversicherungs-Nr.:", "gebaeudeversicherungsnr")}
                {renderStringInput("Kauf-URNr.:", "urnKauf")}
                {renderStringInput("Verkauf-URNr.:", "urnVerkauf")}
                {renderStringInput("Nachgenehmigung-URNr.:", "urnNachgenehmigung")}
                {renderStringInput("Grundschuldbrief-URNr.:", "urnGrundschuldbrief")}

            </div>

        </div>
    )
}

export default PropertyPanel


