import React from "react";
import BankAccountContainer from "./BankAccounts";
import {Tab, Tabs} from "react-bootstrap";
import SearchSettings from "./SearchSettings";
import Users from "./Users";
import Loans from "./Loans";

const SettingsContainer = () => {

    return (
        <div>
            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li>Einstellungen</li>
                </ol>
            </div>
            <Tabs defaultActiveKey="users" id="uncontrolled-tab-example">
                <Tab eventKey="users" title="Benutzer">
                    <Users/>
                </Tab>
                <Tab eventKey="bankAccounts" title="Bankkonten">
                    <BankAccountContainer/>
                </Tab>
                <Tab eventKey="loans" title="Darlehen">
                    <Loans/>
                </Tab>
                <Tab eventKey="search" title="Suche">
                    <SearchSettings/>
                </Tab>
            </Tabs>
        </div>
    )
}

export default SettingsContainer


