import React from "react";
import {formatAmount} from "../common/utils";

const LoanReport = ({rows}) => {

    const renderRow = (row) => (
        <tr key={`lr-${row.id}`}>
            <td className="text-center">{row.id}</td>
            <td className="text-left">{row.name}</td>
            <td className="text-right">{formatAmount(row.annualInterestRate * 100, '0,0')} %</td>
            <td className="text-right">{formatAmount(row.credit, '0,0')}</td>
            <td className="text-right">{formatAmount(row.debit, '0,0')}</td>
            <td className="text-right">{formatAmount(row.accruedInterest, '0,0')}</td>
            <td className="text-right">{formatAmount(row.compoundedCredit, '0,0')}</td>
        </tr>
    )

    return (
        <table className="table table-hover loan-report">
            <thead>
            <tr>
                <th className="text-center">#</th>
                <th className="text-left">Name</th>
                <th className="text-right">Zinssatz</th>
                <th className="text-right">Eingezahlt</th>
                <th className="text-right">Ausgezahlt</th>
                <th className="text-right">Zinsen</th>
                <th className="text-right">Bilanz</th>
            </tr>
            </thead>
            <tbody>
            {rows.map(renderRow)}
            </tbody>
        </table>
    )
}

export default LoanReport