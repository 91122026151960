import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import SelectBox from "../../common/components/SelectBox";
import * as _ from "underscore";
import * as API from "../../common/api";

const BankStatementUploadModal = ({onClose}) => {

    const [state, setState] = useState({bankAccountsByStatementType: {}, statementTypes: []})

    const [errorState, setErrorState] = useState({ error: false, errorMessage: '' })
    const [selectedStatementType, setSelectedStatementType] = useState()
    const [selectedBankAccount, setSelectedBankAccount] = useState()
    const [currentFile, setCurrentFile] = useState()
    const [progressState, setProgressState] = useState({ showProgress: false, progress: 0 })

    useEffect(() => {
        fetchBankAccounts()
    }, [])

    useEffect(() => {
        if (currentFile) {
            uploadStatement(currentFile)
        }
    }, [currentFile])

    const fetchBankAccounts = () => API.fetchBankAccounts().then(response =>  {

        const bankAccounts = response.data

        const bankAccountsByStatementType = _.groupBy(bankAccounts, 'statementType')

        const statementTypes = _.filter(
            _.map(
                _.uniq(bankAccounts, false, a => a.statementType)
                , a => ({type: a.statementType, accountInformationAvailable: a.accountInformationAvailable}))
            , t => t.type !== 'NONE')

        setState({bankAccountsByStatementType: bankAccountsByStatementType, statementTypes: statementTypes})
    })

    const clearError = () => {
        setCurrentFile(null)
        setErrorState({ error: false, errorMessage: '' })
        setProgressState({ showProgress: false, progress: 0 })
    }

    const openFileSelector = (event, dropzoneRef) => {
        event.preventDefault()
        event.stopPropagation()
        dropzoneRef.open()
    }

    const uploadStatement = (file) => {

        const onUploadProgress = (event) => setProgressState({ showProgress: true, progress: (event.loaded / event.total) * 100 })

        API.uploadBankStatement(selectedStatementType.type, selectedStatementType.accountInformationAvailable ? 0 : selectedBankAccount.id, file, onUploadProgress).then(onClose).catch(error => {
            let errorMessage = error.message;
            if (error.response && error.response.data) {
                errorMessage += " - " + error.response.data.message;
            }
            setErrorState({ error: true, errorMessage: errorMessage })
        })
    }

    const uploadStatements = (files) => {
        if (files && files.length > 0) {
            setCurrentFile(files[0])
        }
    }

    const { error, errorMessage } = errorState
    const { showProgress, progress } = progressState

    const { bankAccountsByStatementType, statementTypes } = state

    const disabled = !selectedStatementType || (selectedStatementType && !selectedStatementType.accountInformationAvailable && !selectedBankAccount) || error

    let dropzoneRef;

    return (

        <div className="modal show fade-in">
            <div className={`modal-dialog modal-md`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={onClose}>&times;</button>
                        <h4 className="modal-title">Kontoauszug laden</h4>
                    </div>
                    <div className="modal-body file-upload-modal-body">
                        {error &&
                            <div className="alert alert-danger alert-dismissable">
                                <button type="button" className="close" onClick={clearError}>&times;</button>
                                Oops: {errorMessage}
                            </div>
                        }

                        <div className="statement-upload-selector">

                            <SelectBox placeholder="Typ..."
                                       clearable={true}
                                       values={statementTypes}
                                       selectedValue={selectedStatementType}
                                       valueMapper={t => ({value: t, label: t.type})}
                                       size="sm"
                                       disabled={error}
                                       onChange={setSelectedStatementType}/>

                            {selectedStatementType && !selectedStatementType.accountInformationAvailable &&
                            <SelectBox placeholder="Konto..."
                                       clearable={true}
                                       values={bankAccountsByStatementType[selectedStatementType.type]}
                                       selectedValue={selectedBankAccount}
                                       valueMapper={a => ({value: a, label: a.name})}
                                       size="sm"
                                       disabled={error}
                                       onChange={setSelectedBankAccount}/>
                            }
                        </div>

                        <Dropzone multiple={false}
                                  onDrop={uploadStatements}
                                  disabled={disabled}
                                  noClick={true}
                                  ref={node => dropzoneRef = node}>
                            {({getRootProps, getInputProps}) => (
                                <div className="file-upload-container" {...getRootProps()}>
                                    <input type="file" {...getInputProps()}/>
                                    <div className="file-upload-content">
                                        <div className="text-center text-muted">
                                            <i className="glyphicon glyphicon-cloud-upload" style={{fontSize: "100px"}}/>
                                        </div>
                                        <h1 className="text-center text-muted">Kontoauszug hierher ziehen</h1>
                                        <h3 className="text-center text-muted">- oder -</h3>
                                        <div style={{paddingTop: "15px", marginBottom: "30px", textAlign: "center"}}>
                                            <button type="button"
                                                    className="btn btn-primary"
                                                    disabled={disabled}
                                                    onClick={e => openFileSelector(e, dropzoneRef)}>Dateibrowser öffnen
                                            </button>
                                        </div>

                                        {showProgress &&
                                            <div className="progress" style={{margin: "auto", width: "70"}}>
                                                <div className="progress-bar" role="progressbar" style={{ width : `${progress}%`}}>
                                                    {(currentFile ? currentFile.name : '') + ' - ' + progress + '%'}
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={onClose}>Schliessen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankStatementUploadModal
