import React, {useEffect, useState} from "react";
import CrudTable from "../common/components/CrudTable";
import useCurrentUser from "../shell/useCurrentUser";
import * as API from "../common/api";

const Loans = () => {

    const [loans, setLoans] = useState([])

    const { permissions } = useCurrentUser()

    const columns = [
        { key: 'id',                 label: '#',            type: 'number', editable: false },
        { key: 'name',               label: 'Name',         type: 'text',   editable: permissions.admin },
        { key: 'description',        label: 'Beschreibung', type: 'text',   editable: permissions.admin },
        { key: 'annualInterestRate', label: 'Zins',         type: 'number', editable: permissions.admin },
    ]

    useEffect(() => {
        fetchLoans()
    }, [])

    const fetchLoans = () => {
        return API.fetchLoans().then(response => setLoans(response.data));
    }

    const createLoan = (loan) => {
        return API.createLoan(loan).then(fetchLoans).catch(console.log)
    }

    const updateLoan = (loan) => {
        return API.updateLoan(loan).then(fetchLoans).catch(console.log)
    }

    const deleteLoan = (loan) => {
        return API.deleteLoan(loan.id).then(fetchLoans).catch(console.log)
    }

    return (
        <CrudTable rows={loans}
                   columns={columns}
                   onRowCreated={createLoan}
                   onRowUpdated={updateLoan}
                   onRowDeleted={deleteLoan}/>
    )
}

export default Loans

