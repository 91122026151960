import React, {useEffect, useState} from "react";
import TransactionDateRangePicker from "../../common/components/TransactionDateRangePicker";
import SelectBox from "../../common/components/SelectBox";
import * as _ from "underscore";
import * as API from "../../common/api";

const BankTransactionFilter = ({onFilterChanged}) =>  {

    const [bankAccounts, setBankAccounts] = useState([])
    const [selectedBankAccount, setSelectedBankAccount] = useState()
    const [selectedDateRange, setSelectedDateRange] = useState([])

    useEffect(() => {
        fetchBankAccounts()
    }, [])

    const fetchBankAccounts = () => API.fetchBankAccounts().then(response => setBankAccounts(response.data))

    const refresh = () => {
        onFilterChanged({
            bankAccount: selectedBankAccount,
            fromDate: selectedDateRange ? _.first(selectedDateRange) : null,
            toDate: selectedDateRange ? _.last(selectedDateRange) : null
        })
    }

    return (
        <div className="transaction-criteria bank-transaction-selector">

            <SelectBox placeholder="Bankkonto..."
                       clearable={true}
                       values={bankAccounts}
                       selectedValue={selectedBankAccount}
                       valueMapper={account => ({value: account, label: account.name})}
                       onChange={setSelectedBankAccount}/>

            <TransactionDateRangePicker value={selectedDateRange}
                                        onChange={setSelectedDateRange}/>

            <div className="btn-group">
                <button type="button" className="btn btn-default" onClick={refresh}>
                    <span className="glyphicon glyphicon-refresh"/>
                </button>
            </div>

        </div>
    )

}

export default BankTransactionFilter