import React, {Fragment} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router";
import NavBar from "./modules/shell/NavBar";
import ErrorBoundary from "react-error-boundary";
import Error from "./modules/shell/Error";
import PropertyContainer from "./modules/property/PropertyContainer";
import PropertyGroupContainer from "./modules/property-group/PropertyGroupContainer";
import ImageCollectionContainer from "./modules/image/ImageCollectionContainer";
import SearchContainer from "./modules/search/SearchContainer";
import ActivityContainer from "./modules/activity/ActivityContainer";
import SettingsContainer from "./modules/settings/SettingsContainer";
import ReportContainer from "./modules/reporting/ReportContainer";
import AccountingContainer from "./modules/accounting/AccountingContainer";
import useCurrentUser from "./modules/shell/useCurrentUser";
import {updateSearchPhrase} from "./modules/shell/actions";
import {useDispatch} from "react-redux";

const RescoreApp = () => {

    const history = useHistory()

    const dispatch = useDispatch()

    const { permissions } = useCurrentUser(true)

    const onSearch = (phrase) => {
        dispatch(updateSearchPhrase(phrase))
        if (history.location.pathname !== '/search') {
            history.push(`/search`)
        }
    }

    const onLogout = () => {
        window.location.href = '/';
        window.auth2.signOut();
    }

    return (

        <Fragment>

            <NavBar onSearch={onSearch}
                    onLogout={onLogout}/>

            <ErrorBoundary FallbackComponent={Error}>

                <div className="content-container">
                <Switch>

                    <Route exact path="/"
                           render={() => <Redirect to="/property-groups/bestand"/>}/>

                    <Route path="/property-groups/:group"
                           render={(props) => <PropertyGroupContainer group={props.match.params.group}/>}/>

                    <Route exact path="/images"
                           render={() => <ImageCollectionContainer/>}/>

                    <Route path="/images/:collectionId"
                           render={(props) => <ImageCollectionContainer collectionId={parseInt(props.match.params.collectionId)} />}/>

                    <Route exact path="/search"
                           render={() => <SearchContainer/>}/>

                    <Route exact path="/activity"
                           render={() => <ActivityContainer />}/>

                    <Route exact path="/accounting"
                            render={() => <Redirect to="/accounting/unaccounted-entries"/>}/>

                    {permissions && permissions.accountant &&
                    <Route exact path="/accounting/:tab"
                           render={(props) => <AccountingContainer tab={props.match.params.tab}/>}/>
                    }

                    <Route exact path="/reports"
                           render={() => <Redirect to="/reports/jahreskennzahlen"/>}/>

                    <Route exact path="/reports/:name"
                           render={(props) => <ReportContainer name={props.match.params.name}/>}/>

                    {permissions && permissions.admin &&
                    <Route exact path="/settings"
                           render={() => <SettingsContainer/>}/>
                    }

                    <Route path="/properties/:propertyId"
                           render={(props) => <PropertyContainer propertyId={parseInt(props.match.params.propertyId)}/>}/>

                </Switch>

                </div>
            </ErrorBoundary>

        </Fragment>
    )
}

export default RescoreApp
