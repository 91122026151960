import * as API from "../common/api";
import {action} from "../common/utils";

export const CURRENT_USER_RECEIVED = 'CURRENT_USER_RECEIVED';
export const CURRENT_USER_REMOVED = 'CURRENT_USER_REMOVED';
export const SEARCH_PHRASE_UPDATED = 'SEARCH_PHRASE_UPDATED';

export function fetchCurrentUser() {
    return function(dispatch) {
        API.fetchCurrentUser()
            .then(response => dispatch({ type: CURRENT_USER_RECEIVED, user: response.data }));
    }
}

export function logout() {
    return function(dispatch) {
        dispatch({ type: CURRENT_USER_REMOVED });
    }
}

export function updateSearchPhrase(phrase) {
    return function(dispatch) {
        dispatch({ type: SEARCH_PHRASE_UPDATED, phrase: phrase });
    }
}
