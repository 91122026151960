import React, {useState} from "react";
import Dropzone from "react-dropzone";

const ImageUploadModal = ({onSubmit, onClose}) => {

    const [error, setError] = useState({
        visible: false,
        message: ''
    })

    const [currentFileName, setCurrentFileName] = useState()

    const [progress, setProgress] = useState({
        visible: true,
        percent: 0
    })

    let dropzoneRef

    const onSuccess = () => {
        setTimeout(onClose, 250);
    }

    const onError = (error) => {
        setError({visible: true, message: error.message})
    }

    const clearError = () => {
        setError({visible: false, message: ''})
    };
    
    const onUploadImages = (files) => {
        files.forEach(file => {
            setCurrentFileName(file.name)
            onSubmit(file, onUploadProgress).then(onSuccess).catch(onError)
        })
    }

    const onUploadProgress = (event) => {
        setProgress({
            visible: true,
            percent: (event.loaded / event.total) * 100
        })
    }

    const onOpenFileSelector = (event, dropzoneRef) => {
        event.preventDefault()
        event.stopPropagation()
        dropzoneRef.open()
    }

    return (

        <div className="modal show fade-in">
            <div className={`modal-dialog modal-md`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={onClose}>&times;</button>
                        <h4 className="modal-title">Bilder hinzufügen</h4>
                    </div>
                    <div className={`modal-body file-upload-modal-body`}>
                        {error.visible &&
                            <div className="alert alert-danger alert-dismissable">
                                <button type="button" className="close" data-dismiss="alert" onClick={clearError}>&times;</button>
                                Oops: {error.message}
                            </div>
                        }
                        <Dropzone onDrop={onUploadImages}
                                  noClick={true}
                                  ref={node => dropzoneRef = node}>
                            {({getRootProps, getInputProps}) => (

                                <div className="file-upload-container" {...getRootProps()}>
                                    <input type="file" {...getInputProps()}/>
                                    <div className="file-upload-content">
                                        <div className="text-center text-muted">
                                            <i className="glyphicon glyphicon-picture" style={{fontSize: "100px"}}/>
                                        </div>
                                        <h1 className="text-center text-muted">Bilder hierher ziehen</h1>
                                        <h3 className="text-center text-muted">- oder -</h3>
                                        <div style={{paddingTop: "15px", marginBottom: "30px", textAlign: "center"}}>
                                            <button type="button" className="btn btn-primary" onClick={e => onOpenFileSelector(e, dropzoneRef)}>Dateibrowser öffnen</button>
                                        </div>

                                        {progress.visible &&
                                            <div className="progress" style={{margin: "auto", width: "70"}}>
                                                <div className="progress-bar" role="progressbar" style={{ width : `${progress.percent}%`}}>
                                                    {currentFileName + ' - ' + progress.percent + '%'}
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={onClose}>Schliessen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageUploadModal
