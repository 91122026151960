import React, {Fragment, useEffect, useState} from "react";
import Modal from "../common/components/Modal";
import useCurrentUser from "../shell/useCurrentUser";
import ActionLink from "../common/components/ActionLink";
import * as API from "../common/api";
import FormGroup from "../common/components/FormGroup";
import {groupBy, sortBy} from "underscore";
import {join} from "lodash";

const types = [
    {value: "AGENT", label: "Makler"},
    {value: "ADMINISTRATOR", label: "Verwalter"},
    {value: "SELLER", label: "Verkäufer"},
    {value: "OTHER", label: "Andere"}
]

const ContactEditModal = ({contact, onClose, onSubmit}) => {

    const [fields, setFields] = useState(contact || {})

    const updateField = (field, update) => {
        const updatedFields = Object.assign({}, fields)
        updatedFields[field] = update
        setFields(updatedFields)
    }

    const submit = () => onSubmit(fields)

    return (
        <Modal title="Kontakt bearbeiten"
               closeLabel="Schliessen"
               onClose={onClose}
               submitLabel="Speichern"
               onSubmit={submit}
               classNames="modal-lg">

            <form className="form-horizontal">

                <FormGroup label="Name">
                    <input type="text"
                           className="form-control"
                           value={fields.name || ''}
                           onChange={e => updateField('name', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Typ">
                    <select className="form-control"
                            value={fields.type || 'OTHER'}
                            onChange={e => updateField('type', e.target.value)}>
                        {types.map(label =>
                            <option key={`contact-label-option-${label.value}`} value={label.value}>{label.label}</option>
                        )}
                    </select>
                </FormGroup>

                <FormGroup label="Adresse">
                    <input type="text"
                           className="form-control"
                           value={fields.address || ''}
                           onChange={e => updateField('address', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Telefon">
                    <input type="text"
                           className="form-control"
                           value={fields.phone || ''}
                           onChange={e => updateField('phone', e.target.value)}/>
                </FormGroup>

                <FormGroup label="E-Mail">
                    <input type="text"
                           className="form-control"
                           value={fields.email || ''}
                           onChange={e => updateField('email', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Web">
                    <input type="text"
                           className="form-control"
                           value={fields.web || ''}
                           onChange={e => updateField('web', e.target.value)}/>
                </FormGroup>

            </form>

        </Modal>
    )
}

const Contact = ({contact, onChange}) => {

    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const { permissions } = useCurrentUser()

    const updateContact = (update) => API.updateContact(update).then(onChange)

    const deleteContact = () => API.deleteContact(contact.id).then(onChange)

    const showEditModal = () => setEditModalVisible(true)

    const hideEditModal = () => setEditModalVisible(false)

    const showDeleteModal = () => setDeleteModalVisible(true)

    const hideDeleteModal = () => setDeleteModalVisible(false)

    const formatContactDetails = (contact) => {
        const details = [];
        if (contact.address) {
            details.push(contact.address)
        }
        if (contact.phone) {
            details.push(contact.phone)
        }
        if (contact.email) {
            details.push(contact.email)
        }
        if (contact.web) {
            details.push(contact.web)
        }
        return join(details, ' | ')
    }

    return (
        <Fragment key={`ra-${contact.id}`}>
            <tr>
                <td className="text-left" style={{paddingBottom: "0px"}}>
                    {contact.id} - <strong>{contact.name}</strong>
                </td>
                {permissions.editor &&
                    <td className="text-center" style={{paddingBottom: "1px"}} width="15%">
                        <ActionLink onClick={showEditModal}>
                            <i className="glyphicon glyphicon-edit mr-5"/>
                        </ActionLink>
                        <ActionLink onClick={showDeleteModal}>
                            <i className="glyphicon glyphicon-trash ml-5"/>
                        </ActionLink>
                        {editModalVisible &&
                        <ContactEditModal contact={contact}
                                          onClose={hideEditModal}
                                          onSubmit={updateContact}/>
                        }
                        {deleteModalVisible &&
                        <Modal title="Kontak löschen"
                               closeLabel="Abbrechen"
                               onClose={hideDeleteModal}
                               submitLabel="Löschen"
                               onSubmit={deleteContact}
                               classNames="modal-md">
                            Kontakt #{contact.id} wirklich löschen?
                        </Modal>
                        }
                    </td>
                }
            </tr>
            <tr>
                <td className="text-left" style={{border: "0", paddingTop: "0px"}} colSpan={2}>
                    <span className="text-muted small">{formatContactDetails(contact)}</span>
                </td>
            </tr>
        </Fragment>
    );
}

const ContactPanel = ({propertyId, onChange}) => {

    const [contactsByType, setContactsByType] = useState([])
    const [createModalVisible, setCreateModalVisible] = useState(false)

    const { permissions } = useCurrentUser()

    useEffect(() => {
        fetchContacts()
    }, [propertyId])

    const fetchContacts = () => {
        return API.fetchContacts(propertyId).then(response => {
            const contacts = sortBy(response.data, 'name')
            setContactsByType(groupBy(contacts, a => a.type ? a.type : 'OTHER'))
        })
    }

    const createContact = (agreement) => API.createContact(agreement).then(onContactChanged)

    const showCreateModal = () => setCreateModalVisible(true)

    const hideCreateModal = () => setCreateModalVisible(false)

    const onContactChanged = () => fetchContacts().then(onChange)

    const renderTable = (label, contacts) => {
        return (
            <table key={`contact-table-${label.value}`} className="table table-condensed" style={{marginBottom: "5px"}}>
                <thead>
                <tr>
                    <td colSpan="2">{label.label} ({contacts.length})</td>
                </tr>
                </thead>
                <tbody>
                {contacts.map(contact => <Contact key={`contact-row-${contact.id}`} contact={contact} onChange={onContactChanged}/>)}
                </tbody>
            </table>
        )
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <span className="glyphicon glyphicon-briefcase"/> Kontakte
                <div className="pull-right">
                    {permissions.editor &&
                    <ActionLink onClick={showCreateModal}>
                        <i className="glyphicon glyphicon-plus"/> Kontakt hinzufügen
                    </ActionLink>
                    }
                </div>
            </div>
            <div className="panel-body">
                {types.filter(type => contactsByType[type.value] && contactsByType[type.value].length > 0).map(type =>
                    renderTable(type, contactsByType[type.value])
                )}
            </div>

            {createModalVisible &&
                <ContactEditModal contact={{propertyId: propertyId}}
                                  onClose={hideCreateModal}
                                  onSubmit={createContact}/>
            }

        </div>
    )
}

export default ContactPanel