import React, {useState} from "react";

const Modal = ({title, closeLabel, onClose, submitLabel, onSubmit, children, classNames}) => {

    const [state, setState] = useState({
        error: false,
        errorMessage: ''
    })

    const onError = (error) => {
        setState({error: true, errorMessage: error.message})
    }

    const clearError = () => {
        setState({error: false, errorMessage: ''})
    }

    const submit = () => {
        onSubmit().then(onClose).catch(onError);
    }

    const { error, errorMessage } = state;

    return (
        <div className="modal show fade-in">
            <div className={`modal-dialog ${classNames}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                    </div>
                    <div className={`modal-body`}>
                        {error &&
                            <div className="alert alert-danger alert-dismissable">
                                <button type="button" className="close" onClick={clearError}>&times;</button>
                                Oops: {errorMessage}
                            </div>
                        }
                        {children}
                    </div>
                    <div className="modal-footer">
                        {submitLabel &&
                        <button type="button" className="btn btn-primary" onClick={submit}>{submitLabel}</button>
                        }
                        <button type="button" className="btn btn-default" onClick={onClose}>{closeLabel}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal