import {CURRENT_USER_RECEIVED, CURRENT_USER_REMOVED, SEARCH_PHRASE_UPDATED} from "./actions";

const initialState = {
    currentUser: null,
    searchPhrase: ''
};

export function reducer(state = initialState, action) {

    switch (action.type) {

        case CURRENT_USER_RECEIVED:
            return Object.assign({}, state, {
                currentUser: action.user
            });

        case CURRENT_USER_REMOVED:
            return Object.assign({}, state, {
                currentUser: null
            });

        case SEARCH_PHRASE_UPDATED:
            return Object.assign({}, state, {
                searchPhrase: action.phrase
            });

        default:
            return state;
    }
}
