import React, {Fragment, useState} from "react";
import {formatDate} from "../common/utils";
import NavLink from "../common/components/NavLink";

import {useTranslation} from 'react-i18next';
import ActionLink from "../common/components/ActionLink";
import PropertyChangesModal from "../common/components/PropertyChangesModal";

const Activity = ({activity}) => {

    const [showChanges, setShowChanges] = useState(false)

    const { t } = useTranslation('changes')

    const propId = activity.propertyId
    const colId = activity.imageCollectionId

    const onShowChangesModal = () =>  {
        setShowChanges(true)
    }

    const onCloseChangesModal = () =>  {
        setShowChanges(false)
    }

    const renderPropertyCreated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> erstellt</span>
        </Fragment>
    )

    const renderPropertyUpdated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> bearbeitet</span>
            { renderChanges() }
        </Fragment>
    )

    const renderPropertyDeleted = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> gelöscht</span>
        </Fragment>
    )

    const renderRentalAgreementCreated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat den Mietvertrag #{ activity.rentalAgreementId } ({ activity.text }) angelegt (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
        </Fragment>
    )

    const renderRentalAgreementUpdated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat den Mietvertrag #{ activity.rentalAgreementId } ({ activity.text }) bearbeitet (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
            { renderChanges() }
        </Fragment>
    )

    const renderRentalAgreementDeleted = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat den Mietvertrag #{ activity.rentalAgreementId } ({ activity.text }) gelöscht (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
        </Fragment>
    )

    const renderContactCreated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat den Kontakt #{ activity.contactId } ({ activity.text }) angelegt (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
        </Fragment>
    )

    const renderContactUpdated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat den Kontakt #{ activity.contactId } ({ activity.text }) bearbeitet (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
            { renderChanges() }
        </Fragment>
    )

    const renderContactDeleted = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat den Kontakt #{ activity.contactId } ({ activity.text }) gelöscht (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
        </Fragment>
    )

    const renderCommentCreated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat einen Kommentar hinzugefügt (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
            { activity.text }
        </Fragment>
    )

    const renderCommentUpdated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat einen Kommentar bearbeitet (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
            { activity.text }
        </Fragment>
    )

    const renderCommentDeleted = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat einen Kommentar gelöscht (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
            { activity.text }
        </Fragment>
    )

    const renderLikeUpdated = () => {
        const imageClassNames = activity.text === 'true' ? 'glyphicon glyphicon-thumbs-up' : 'glyphicon glyphicon-thumbs-down'
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat über <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> abgestimmt: <i className={imageClassNames}/></span>
            </Fragment>
        )
    }

    const renderAttachmentCreated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat einen Anhang hochgeladen (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
            { activity.text }
        </Fragment>
    )

    const renderAttachmentUpdated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat einen Anhang geändert (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
            { activity.text }
            { renderChanges(activity) }
        </Fragment>
    )

    const renderAttachmentDeleted = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat einen Anhang gelöscht (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
            { activity.text }
        </Fragment>
    )

    const renderImageCollectionCreated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat das Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink> erstellt</span>
        </Fragment>
    )

    const renderImageCollectionUpdated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat das Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink> bearbeitet</span>
        </Fragment>
    )

    const renderImageCollectionDeleted = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat das Album </span>
            '{activity.text}'
            <span className="text-muted"> gelöscht</span>
        </Fragment>
    )

    const renderImageCreated = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat ein <NavLink path={`/api/images/${colId}/${activity.imageId}?image-set=MASTER`}>Bild</NavLink> hochgeladen (Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink>)</span>
        </Fragment>
    )

    const renderImageDeleted = () => (
        <Fragment>
            { activity.userName }
            <span className="text-muted"> hat ein Bild gelöscht (Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink>)</span>
        </Fragment>
    )

    const renderChanges = () => (
        <div>
            <div className="spacer5"/>
            <ul>
                {activity.changes.length > 0 && activity.changes.map(c =>
                    <li key={activity.id + '-' + c.name}><strong>{t(`changes:${activity.type}.${c.name}`)}</strong></li>
                )}
            </ul>
            <ActionLink onClick={onShowChangesModal}>Details</ActionLink>
            {showChanges &&
                <PropertyChangesModal activity={activity} onClose={onCloseChangesModal}/>
            }
        </div>
    )

    const renderers = {
        PROPERTY_CREATED         : { iconClassNames: "glyphicon glyphicon-file",      render: renderPropertyCreated },
        PROPERTY_UPDATED         : { iconClassNames: "glyphicon glyphicon-file",      render: renderPropertyUpdated },
        PROPERTY_DELETED         : { iconClassNames: "glyphicon glyphicon-file",      render: renderPropertyDeleted },
        RENTAL_AGREEMENT_CREATED : { iconClassNames: "glyphicon glyphicon-briefcase", render: renderRentalAgreementCreated },
        RENTAL_AGREEMENT_UPDATED : { iconClassNames: "glyphicon glyphicon-briefcase", render: renderRentalAgreementUpdated },
        RENTAL_AGREEMENT_DELETED : { iconClassNames: "glyphicon glyphicon-briefcase", render: renderRentalAgreementDeleted },
        CONTACT_CREATED          : { iconClassNames: "glyphicon glyphicon-user",      render: renderContactCreated },
        CONTACT_UPDATED          : { iconClassNames: "glyphicon glyphicon-user",      render: renderContactUpdated },
        CONTACT_DELETED          : { iconClassNames: "glyphicon glyphicon-user",      render: renderContactDeleted },
        COMMENT_CREATED          : { iconClassNames: "glyphicon glyphicon-comment",   render: renderCommentCreated },
        COMMENT_UPDATED          : { iconClassNames: "glyphicon glyphicon-comment",   render: renderCommentUpdated },
        COMMENT_DELETED          : { iconClassNames: "glyphicon glyphicon-comment",   render: renderCommentDeleted },
        LIKE_UPDATED             : { iconClassNames: "glyphicon glyphicon-thumbs-up", render: renderLikeUpdated },
        ATTACHMENT_CREATED       : { iconClassNames: "glyphicon glyphicon-paperclip", render: renderAttachmentCreated },
        ATTACHMENT_UPDATED       : { iconClassNames: "glyphicon glyphicon-paperclip", render: renderAttachmentUpdated },
        ATTACHMENT_DELETED       : { iconClassNames: "glyphicon glyphicon-paperclip", render: renderAttachmentDeleted },
        IMG_COL_CREATED          : { iconClassNames: "glyphicon glyphicon-picture",   render: renderImageCollectionCreated },
        IMG_COL_UPDATED          : { iconClassNames: "glyphicon glyphicon-picture",   render: renderImageCollectionUpdated },
        IMG_COL_DELETED          : { iconClassNames: "glyphicon glyphicon-picture",   render: renderImageCollectionDeleted },
        IMG_CREATED              : { iconClassNames: "glyphicon glyphicon-picture",   render: renderImageCreated },
        IMG_DELETED              : { iconClassNames: "glyphicon glyphicon-picture",   render: renderImageDeleted },
    }

    return (
        <tr style={{fontSize: "12px"}}>
            <td className="text-left text-nowrap">
                <span style={{marginRight: "10px"}}><strong>{formatDate(activity.createTime, 'HH:mm')}</strong></span>
                <i className={renderers[activity.type].iconClassNames} style={{marginRight: "10px"}}/>
            </td>
            <td className="text-left">
                {renderers[activity.type].render(activity)}
            </td>
        </tr>
    )
}

export default Activity
