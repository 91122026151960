import React, {Fragment, useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroller';
import LedgerEntryList from "./LedgerEntryList";
import {groupRowsByKey, noOp} from "../../common/utils";
import * as API from "../../common/api";
import BookingModal from "./BookingModal";

const UnaccountedLedgerEntryContainer = () => {

    const DEFAULT_LEDGER_ENTRIES = {
        entriesByDate: [],
        loadedSize: 0,
        totalSize: 0,
        debitAmount: 0,
        creditAmount: 0,
    }

    const [ledgerEntries, setLedgerEntries] = useState(DEFAULT_LEDGER_ENTRIES)
    const [bookingModalVisible, setBookingModalVisible] = useState(false)
    const [selectedEntry, setSelectedEntry] = useState()

    useEffect(() => {
        fetchUnaccountedLedgerEntries()
    }, [])

    const fetchUnaccountedLedgerEntries = () => {
        API.fetchUnaccountedLedgerEntries().then(response => {
            const page = response.data
            if (page.rows.length === 0) {
                setLedgerEntries(DEFAULT_LEDGER_ENTRIES)
            } else {
                setLedgerEntries({
                    entriesByDate: groupRowsByKey(page.index === 0 ? [] : [...ledgerEntries.entriesByDate], page.rows, le => new Date(le.valueDate).getTime()),
                    loadedSize: (page.index * page.size) + page.rows.length,
                    totalSize: page.metadata.totalSize,
                    debitAmount: page.metadata.debitAmount,
                    creditAmount: page.metadata.creditAmount,
                })
            }
        })
    }

    const showBookingModal = (entry) => {
        setBookingModalVisible(true)
        setSelectedEntry(entry)
    }

    const hideBookingModal = () => {
        setBookingModalVisible(false)
        setSelectedEntry(null)
        fetchUnaccountedLedgerEntries()
    }

    const { entriesByDate, debitAmount, creditAmount } = ledgerEntries;

    return (
        <Fragment>
            <InfiniteScroll
                pageStart={0}
                hasMore={false}
                loadMore={noOp}
                loader={<div className="loader" key={0}>Loading ...</div>}>
                <LedgerEntryList entriesByDate={entriesByDate}
                                 debitAmount={debitAmount}
                                 creditAmount={creditAmount}
                                 onEntrySelected={showBookingModal}/>
            </InfiniteScroll>
            {bookingModalVisible &&
                <BookingModal entry={selectedEntry}
                              onClose={hideBookingModal}/>
            }
        </Fragment>

    )
}

export default UnaccountedLedgerEntryContainer