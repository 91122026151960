import React, {useState} from "react";
import * as API from "../common/api";

const SearchSettings = () => {

	const [searchIndexIsBuilding, setSearchIndexIsBuilding] = useState(false)

	const rebuildSearchIndex = () => {
		setSearchIndexIsBuilding(true)
		return API.buildSearchIndex().then(() => {
			setSearchIndexIsBuilding(false)
		}).catch(console.log);
	}

	return (
		<div className="row">
			<div className="col-md-12">
				{searchIndexIsBuilding ?
					(
						<button type="button"
						        className='btn btn-primary btn-large center-on-page'
						        style={{marginTop: "100px"}}
						        disabled={true}>
							<span className="glyphicon glyphicon-refresh glyphicon-refresh-animate mr-5"/>Suchindex wird
							erstellt
						</button>
					) : (
						<button type="button"
						        className='btn btn-primary btn-large center-on-page'
						        style={{marginTop: "100px"}}
						        onClick={() => rebuildSearchIndex()}>
							Suchindex neu erstellen
						</button>
					)
				}
			</div>
		</div>
	)
}

export default SearchSettings

