import React, {useEffect, useState} from "react";
import * as API from "../common/api";
import {useHistory} from "react-router";
import useCurrentUser from "../shell/useCurrentUser";
import {keys} from "underscore";
import AttachmentPanel from "./AttachmentPanel";
import RentalAgreementPanel from "./RentalAgreementPanel";
import CommentForm from "./CommentForm";
import ActivityPanel from "./ActivityPanel";
import PropertyPanel from "./PropertyPanel";
import Modal from "../common/components/Modal";
import ImagePanel from "./ImagePanel";
import ActionLink from "../common/components/ActionLink";
import {capitalize} from "lodash";
import ContactPanel from "./ContactPanel";

const PropertyContainer = ({propertyId}) => {

    const [property, setProperty] = useState()

    const [likes, setLikes] = useState([])
    const [activities, setActivities] = useState([])
    const [updatedFields, setUpdatedFields] = useState({})
    const [confirmCopyModalVisible, setConfirmCopyModalVisible] = useState(false)

    const history = useHistory()

    const { permissions } = useCurrentUser()

    useEffect(() => {
        fetchProperty()
        fetchLikes()
        fetchActivities()
    }, [propertyId])

    const fetchProperty = () => API.fetchProperty(propertyId).then(response => setProperty(response.data))

    const fetchActivities = () => API.fetchActivitiesByPropertyId(propertyId).then(response => setActivities(response.data.rows))

    const onSavePropertyFieldUpdates = () => API.updatePropertyFields(propertyId, updatedFields).then(() => {
        setUpdatedFields({})
        fetchProperty()
        fetchActivities()
    })

    const updateCoverCollectionId = (collectionId) => API.updatePropertyFields(propertyId, { imageCollectionId: collectionId }).then(() => {
        fetchProperty()
        fetchActivities()
    })

    const onCancelPropertyFieldUpdates = () => {
        setUpdatedFields({})
        fetchProperty()
    }

    const onPropertyFieldsUpdated = (fields) => setUpdatedFields(fields)

    const isPropertyDirty = () => updatedFields && keys(updatedFields).length > 0

    const showConfirmCopyModal = () => setConfirmCopyModalVisible(true)

    const hideConfirmCopyModal = () => setConfirmCopyModalVisible(false)

    const copyProperty = () => API.copyProperty(propertyId).then(response => history.push(`/properties/${response.data.id}`))

    const fetchLikes = () => API.fetchLikes(propertyId).then(response => setLikes(response.data))

    const onLikeUpdated = (value) => API.updateLike(propertyId, value).then(() => {
        fetchLikes()
        fetchActivities()
    })

    const onSubmitComment = (comment) => API.createComment(propertyId, comment).then(fetchActivities)

    if (!property) {
        return (
            <p>Loading...</p>
        )
    }

    const propertyAddress = (property) => {
        const fields = property.fields
        if (!fields) {
            return ''
        }
        let address = []
        if (fields.plz && fields.plz.trim().length > 0) {
            address.push(fields.plz.trim())
        }
        if (fields.stadt && fields.stadt.trim().length > 0) {
            address.push(fields.stadt.trim())
        }
        if (fields.strasseUndHausnr && fields.strasseUndHausnr.trim().length > 0) {
            address.push(fields.strasseUndHausnr.trim())
        }
        return address.join(" ")
    }

    return  (

        <div>

            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li>{capitalize(property.group)}</li>
                    <li>{property.id}</li>
                </ol>
                <ul className="nav nav-pills pull-right">
                    {permissions.editor && isPropertyDirty() &&
                        <li><ActionLink onClick={onSavePropertyFieldUpdates}><i className="glyphicon glyphicon-ok"/><label>Speichern</label></ActionLink></li>
                    }
                    {permissions.editor && isPropertyDirty() &&
                        <li><ActionLink onClick={onCancelPropertyFieldUpdates}><i className="glyphicon glyphicon-remove"/><label>Verwerfen</label></ActionLink></li>
                    }
                    {permissions.editor &&
                        <li><ActionLink onClick={showConfirmCopyModal}><i className="halflings halflings-duplicate"/><label>Kopieren</label></ActionLink></li>
                    }
                    <li>
                        <a href={`https://maps.google.de/maps?q=${propertyAddress(property)}`} target="_blank" rel="noopener noreferrer">
                            <i className="glyphicon glyphicon-map-marker"/><label>Karte</label>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="row property-container">

                <div className="col-md-4">
                    <PropertyPanel property={property}
                                   likes={likes}
                                   onFieldsUpdated={onPropertyFieldsUpdated}
                                   onLikeUpdated={onLikeUpdated}/>
                </div>

                <div className="col-md-4">
                    <CommentForm onSubmit={onSubmitComment}/>
                    <ActivityPanel activities={activities}
                                   onChange={fetchActivities}/>
                </div>

                <div className="col-md-4">
                    <ImagePanel propertyId={propertyId} coverCollectionId={property.imageCollectionId} onUpdateCoverCollectionId={updateCoverCollectionId}/>
                    {permissions && (permissions.admin || permissions.editor) &&
                        <AttachmentPanel propertyId={propertyId}
                                         onChange={fetchActivities}/>
                    }
                    <RentalAgreementPanel propertyId={propertyId}
                                          onChange={fetchActivities}/>

                    <ContactPanel propertyId={propertyId}
                                  onChange={fetchActivities}/>
                </div>

            </div>

            {confirmCopyModalVisible &&
                <Modal title="Bestätigung"
                       closeLabel="Abbrechen"
                       onClose={hideConfirmCopyModal}
                       submitLabel="Kopieren"
                       onSubmit={copyProperty}
                       classNames="modal-md">
                    Objekt #{property.id} kopieren?
                </Modal>
            }
        </div>
    )
}

export default PropertyContainer


