import React, {useState} from "react";

const CommentForm = ({initValue, onSubmit, style = {}}) => {

    const [value, setValue] = useState(initValue)

    const onChange = (event) => {
        setValue(event.target.value)
    }

    const onClick = (event) => {
        event.preventDefault()
        setValue('')
        onSubmit(value)
    }

    return (
        <form className="hidden-print" style={style}>
            <div className="form-group">
            <textarea className="form-control" rows="3" placeholder="Kommentar hinterlassen"
                      style={{fontSize: "12px"}} value={value} onChange={onChange}/>
            </div>
            <div style={{textAlign: "right"}}>
                <button type="submit" className="btn btn-default btn-sm" onClick={onClick}>Abschicken</button>
            </div>
        </form>
    )
}

export default CommentForm