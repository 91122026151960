import React from "react";
import Modal from "../../common/components/Modal";
import {formatAmount, formatDate} from "../../common/utils";

const BankTransactionDetailsModal = ({bankTransaction, onClose}) => {

    return (

        <Modal title={`Transaktionsdetails`}
               closeLabel="Schliessen"
               onClose={onClose}
               classNames="modal-lg">

            <table className="table table-condensed">
                <tbody>
                    <tr className="active">
                        <th className="text-center" colSpan={2}>Transaktion #{bankTransaction.id}</th>
                    </tr>
                    <tr>
                        <th>Konto</th>
                        <td>{bankTransaction.bankAccountId}</td>
                    </tr>
                    <tr>
                        <th>Betrag</th>
                        <td>{formatAmount(bankTransaction.amount)}</td>
                    </tr>
                    <tr>
                        <th>Referenz</th>
                        <td>{bankTransaction.reference}</td>
                    </tr>
                    <tr>
                        <th>Wertstellungsdatum</th>
                        <td>{formatDate(bankTransaction.valueDate, 'DD.MM.YYYY')}</td>
                    </tr>
                    <tr>
                        <th>Buchungsdatum</th>
                        <td>{formatDate(bankTransaction.bookingDate, 'DD.MM.YYYY')}</td>
                    </tr>
                    <tr>
                        <th>Importiert</th>
                        <td>{formatDate(bankTransaction.createTime)}</td>
                    </tr>
                    <tr className="active">
                        <th className="text-center" colSpan={2}>Weitere Details</th>
                    </tr>
                    {Object.keys(bankTransaction.details).map(field =>
                        <tr key={field}>
                            <td>{field}</td>
                            <td>{bankTransaction.details[field]}</td>
                        </tr>
                    )}
                </tbody>
            </table>

        </Modal>
    )
}

export default BankTransactionDetailsModal