import React, {useEffect, useState} from "react";
import CrudTable from "../common/components/CrudTable";
import useCurrentUser from "../shell/useCurrentUser";
import * as API from "../common/api";

const Users = () => {

    const [users, setUsers] = useState([])

    const { permissions } = useCurrentUser()

    const columns = [
        { key: 'id',         label: '#',             type: 'number', editable: false },
        { key: 'firstName',  label: 'Vorname',       type: 'text',   editable: permissions.admin },
        { key: 'lastName',   label: 'Nachname',      type: 'text',   editable: permissions.admin },
        { key: 'email',      label: 'E-Mail',        type: 'text',   editable: permissions.admin },
        { key: 'admin',      label: 'Administrator', type: 'bool',   editable: permissions.admin },
        { key: 'editor',     label: 'Ändern',        type: 'bool',   editable: permissions.admin },
        { key: 'voter',      label: 'Abstimmen',     type: 'bool',   editable: permissions.admin },
        { key: 'accountant', label: 'Buchhaltung',   type: 'bool',   editable: permissions.admin },
        { key: 'locked',     label: 'Gesperrt',      type: 'bool',   editable: permissions.admin }
    ]

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = () => {
        return API.fetchUsers().then(response => setUsers(response.data));
    }

    const createUser = (user) => {
        return API.createUser(user).then(fetchUsers).catch(console.log)
    }

    const updateUser = (user) => {
        return API.updateUser(user).then(fetchUsers).catch(console.log)
    }

    const deleteUser = (user) => {
        return API.deleteUser(user.id).then(fetchUsers).catch(console.log)
    }

    return (
        <CrudTable rows={users}
                   columns={columns}
                   onRowCreated={createUser}
                   onRowUpdated={updateUser}
                   onRowDeleted={deleteUser}/>
    )
}

export default Users

