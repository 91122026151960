import React, {Fragment, useEffect, useState} from "react";
import Modal from "../common/components/Modal";

import Dropzone from "react-dropzone";
import {groupBy, sortBy} from "underscore";
import {downloadFile} from "../common/request";
import * as API from "../common/api";
import useCurrentUser from "../shell/useCurrentUser";
import ActionLink from "../common/components/ActionLink";
import FormGroup from "../common/components/FormGroup";

const labels = [
    {value: "Verträge", label: "Verträge"},
    {value: "Dokumente", label: "Dokumente"},
    {value: "Abrechnungen", label: "Abrechnungen"},
    {value: "Abrechnung WEG", label: "Abrechnung WEG"},
    {value: "Abrechnung Mieter", label: "Abrechnung Mieter"},
    {value: "Andere", label: "Andere"},
    {value: "none", label: "Ohne Label"}
]

const AttachmentEditModal = ({attachment, onClose, onSubmit}) => {

    const [fields, setFields] = useState(attachment)

    const updateField = (field, update) => {
        const updatedFields = Object.assign({}, fields)
        updatedFields[field] = update
        setFields(updatedFields)
    }

    const submit = () => onSubmit(fields)

    return (
        <Modal title="Anhang bearbeiten"
               closeLabel="Schliessen"
               onClose={onClose}
               submitLabel="Speichern"
               onSubmit={submit}
               classNames="modal-lg">

            <form className="form-horizontal">

                <FormGroup label="Name">
                    <input type="text"
                           className="form-control"
                           value={fields.filename || ''}
                           onChange={e => updateField('filename', e.target.value)}/>
                </FormGroup>

                <FormGroup label="Label">
                    <select className="form-control"
                            value={fields.label || 'none'}
                            onChange={e => updateField('label', e.target.value)}>
                        {labels.map(label =>
                            <option key={`attachment-label-option-${label.value}`} value={label.value}>{label.label}</option>
                        )}
                    </select>

                    {/*<SelectBox values={this.flowTypes}*/}
                    {/*           valueMapper={this.mapFlowType}*/}
                    {/*           selectedValue={leg.targetFlowType}*/}
                    {/*           size="sm"*/}
                    {/*           disabled={disabled}*/}
                    {/*           onChange={flowType => this.updateTargetFlowType(idx, flowType)}/>*/}
                </FormGroup>

            </form>

        </Modal>
    )
}

const Attachment = ({attachment, onChange}) => {

    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const { permissions } = useCurrentUser()

    const updateAttachment = (fields) => API.updateAttachment(fields).then(onChange)

    const deleteAttachment = () => API.deleteAttachment(attachment.id).then(onChange)

    const showEditModal = () => setEditModalVisible(true)

    const hideEditModal = () => setEditModalVisible(false)

    const showDeleteModal = () => setDeleteModalVisible(true)

    const hideDeleteModal = () => setDeleteModalVisible(false)

    const download = () => {
        downloadFile(`/api/attachments/${attachment.id}`)
    }

    return (
        <tr>
            <td className="text-left" width="85%">
                <ActionLink onClick={download}>{attachment.filename}</ActionLink>
            </td>
            {permissions.editor &&
            <td className="text-center" width="15%">
                <ActionLink onClick={showEditModal}>
                    <i className="glyphicon glyphicon-edit mr-5"/>
                </ActionLink>
                <ActionLink onClick={showDeleteModal}>
                    <i className="glyphicon glyphicon-trash ml-5"/>
                </ActionLink>
                {editModalVisible &&
                    <AttachmentEditModal attachment={attachment}
                                         onClose={hideEditModal}
                                         onSubmit={updateAttachment}/>
                }
                {deleteModalVisible &&
                    <Modal title="Anhang löschen"
                           closeLabel="Abbrechen"
                           onClose={hideDeleteModal}
                           submitLabel="Löschen"
                           onSubmit={deleteAttachment}
                           classNames="modal-md">
                        Anhang '{attachment.filename}' wirklich löschen?
                    </Modal>
                }
            </td>
            }
        </tr>
    )
}

const AttachmentPanel = ({propertyId, onChange}) => {

    const [attachmentsByLabel, setAttachmentsByLabel] = useState([])
    const [size, setSize] = useState(0)
    const [currentFileName, setCurrentFileName] = useState('')
    const [progress, setProgress] = useState({
        visible: true,
        percent: 0
    })

    const { permissions } = useCurrentUser()

    let attachmentInput

    useEffect(() => {
        fetchAttachments()
    }, [propertyId])

    const fetchAttachments = () => {
        return API.fetchAttachments(propertyId).then(response => {
            const attachments = sortBy(response.data, 'filename')
            setAttachmentsByLabel(groupBy(attachments, a => a.label ? a.label : 'none'))
            setSize(attachments.length)
        })
    }

    const onUploadAttachment = (file, onUploadProgress) => {
        return API.uploadAttachment(propertyId, file, onUploadProgress).then(() => {
            setTimeout(() => {
                setProgress({visible: false, percent: 0})
            }, 250);
            onAttachmentChanged()
        }).catch(console.log)
    }

    const setAttachmentInput = (element) => {
        attachmentInput = element;
    };

    const onOpenFileSelector = () => {
        attachmentInput.click()
    };

    const onUploadAttachments = (files) => {
        files.forEach(file => {
            setCurrentFileName(file.name)
            onUploadAttachment(file, onUploadProgress)
        });
    };

    const onUploadProgress = (event) => {
        setProgress({
            visible: true,
            percent: event.loaded / event.total * 100
        })
    };

    const onAttachmentChanged = () => {
        fetchAttachments()
        onChange()
    }

    const renderTable = (label, attachments) => {
        return (
            <table key={`attachment-table-${label.value}`} className="table table-condensed" style={{marginBottom: "5px"}}>
                <thead>
                <tr>
                    <td colSpan="2">{label.label} ({attachments.length})</td>
                </tr>
                </thead>
                <tbody>
                    {attachments.map(attachment => <Attachment  key={`attachment-row-${attachment.id}`} attachment={attachment} onChange={onAttachmentChanged}/>)}
                </tbody>
            </table>
        )
    }

    return (
        <Fragment>
            <Dropzone onDrop={onUploadAttachments} noClick={true}>
                {({getRootProps, getInputProps}) => (
                    <div className="panel panel-default" {...getRootProps()}>
                        <div className="panel-heading">
                            <span className="glyphicon glyphicon-paperclip"/> Anhänge ({size})
                            <div className="pull-right">
                                {permissions.editor &&
                                    <ActionLink stopPropagation={true} onClick={onOpenFileSelector}>
                                        <i className="glyphicon glyphicon-plus"/> Anhang hinzufügen
                                    </ActionLink>
                                }
                            </div>
                        </div>
                        <div className="panel-body">

                            {progress.visible &&
                                <div className="progress" style={{marginBottom: "0px", marginTop: "10px"}}>
                                    <div className="progress-bar" role="progressbar" style={{ width : `${progress.percent}%`}}>
                                        {currentFileName + ' - ' + progress.percent + '%'}
                                    </div>
                                </div>
                            }

                            {permissions.editor &&
                                <input type="file" {...getInputProps()} ref={setAttachmentInput}/>
                            }
                            {permissions.editor &&
                                <p className="text-center text-muted"><small>Tipp: Anhänge zum Hochladen hier ablegen</small></p>
                            }
                            {labels.filter(label => attachmentsByLabel[label.value] && attachmentsByLabel[label.value].length > 0).map(label =>
                                renderTable(label, attachmentsByLabel[label.value])
                            )}
                        </div>
                    </div>
                )}
            </Dropzone>

        </Fragment>
    )
}

export default AttachmentPanel
