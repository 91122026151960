import React, {useEffect, useState} from "react";
import ThumbnailGallery from "../common/components/ThumbnailGallery";
import {formatDate} from "../common/utils";
import Modal from "../common/components/Modal";
import ImageCollectionEditModal from "./ImageCollectionEditModal";
import ImageUploadModal from "./ImageUploadModal";
import {propertyName} from "../common/image";
import NavLink from "../common/components/NavLink";
import * as API from "../common/api";
import useCurrentUser from "../shell/useCurrentUser";
import ActionLink from "../common/components/ActionLink";

const ImageCollection = ({collection, propertiesById, onChange}) => {

    const [descriptors, setDescriptors] = useState([])
    const [imageUploadModalVisible, setImageUploadModalVisible] = useState(false)
    const [imageDeleteModalVisible, setImageDeleteModalVisible] = useState(false)
    const [collectionEditModalVisible, setCollectionEditModalVisible] = useState(false)
    const [collectionDeleteModalVisible, setCollectionDeleteModalVisible] = useState(false)
    const [imageDescriptorToDelete, setImageDescriptorToDelete] = useState()

    const { permissions } = useCurrentUser()

    useEffect(() => {
        fetchDescriptors()
    }, [collection.id])

    const fetchDescriptors = () => API.fetchImageDescriptorsByCollectionId(collection.id).then(response => setDescriptors(response.data))

    const updateCollection = (update) => API.updateImageCollection(update).then(onChange)

    const deleteCollection = () => API.deleteImageCollection(collection.id).then(onChange)

    const uploadImage = (file, onUploadProgress) => API.uploadImage(collection.id, file, onUploadProgress).then(fetchDescriptors)

    const deleteImage = () => API.deleteImage(collection.id, imageDescriptorToDelete.id).then(fetchDescriptors)

    const showImageUploadModal = () => setImageUploadModalVisible(true)

    const hideImageUploadModal = () => setImageUploadModalVisible(false)

    const showImageDeleteModal = (descriptor) => {
        setImageDescriptorToDelete(descriptor)
        setImageDeleteModalVisible(true)
    }

    const hideImageDeleteModal = () => {
        setImageDeleteModalVisible(false)
        setImageDescriptorToDelete(null)
    }

    const showCollectionEditModal = () => setCollectionEditModalVisible(true)

    const hideCollectionEditModal = () => setCollectionEditModalVisible(false)

    const showCollectionDeleteModal = () => setCollectionDeleteModalVisible(true)

    const hideCollectionDeleteModal = () => setCollectionDeleteModalVisible(false)

    return (
        <div className="row">
            <div className="col-xs-12">
                <div className="imgcol">

                    <div className="imgcol-header">
                        <div className="pull-left"><h3>{collection.name}</h3></div>
                        <div className="pull-right imgcol-actions">
                            <div className="btn-group">
                                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                    Aktionen&nbsp;<span className="caret"/>
                                </button>
                                <ul className="dropdown-menu pull-right" role="menu">
                                    {permissions.editor &&
                                    <li><ActionLink onClick={showImageUploadModal}><i className="glyphicon glyphicon-plus"/> Bilder hinzufügen</ActionLink></li>
                                    }
                                    {permissions.editor &&
                                    <li className="divider"/>
                                    }
                                    {permissions.editor &&
                                    <li><ActionLink onClick={showCollectionEditModal}><i className="glyphicon glyphicon-edit"/> Album bearbeiten</ActionLink></li>
                                    }
                                    {permissions.editor &&
                                    <li><ActionLink onClick={showCollectionDeleteModal}><i className="glyphicon glyphicon-trash"/> Album löschen</ActionLink>
                                    </li>
                                    }
                                    {permissions.editor &&
                                    <li className="divider"/>
                                    }
                                    <li><a href={`/api/images/${collection.id}`} download={`${collection.id}.zip`}><i className="glyphicon glyphicon-download-alt"/> Album herunterladen</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {collection.description &&
                        <div className="imgcol-description">
                            <small>
                                <strong>Beschreibung:</strong>&nbsp;<span className="text-muted">{collection.description}</span>
                            </small>
                        </div>
                    }

                    <ThumbnailGallery descriptors={descriptors}
                                      collection={collection}
                                      editable={permissions.editor || false}
                                      downloadEnabled={true}
                                      scalingFactor={1}
                                      onUpdateCollection={collection => updateCollection(collection, () => {}, () => {})}
                                      onDeleteImage={showImageDeleteModal}/>

                    <div className="imgcol-footer">
                        <small className="text-muted">
                            {descriptors.length} {descriptors.length === 1 ? 'Bild' : 'Bilder'} - Erstellt am {formatDate(collection.createTime)}, letzte Änderung am {formatDate(collection.updateTime)}
                            {collection.propertyIds.length > 0 && collection.propertyIds.filter(id => propertiesById[id]).map(id => (
                                <span key={`img-col-prop-${collection.id}-${id}`}>
                                    <span>&nbsp;|&nbsp;</span>
                                    <NavLink path={`/properties/${id}`}>{propertyName(propertiesById[id])}</NavLink>
                                </span>
                            ))}
                        </small>
                    </div>

                </div>
            </div>

            {imageUploadModalVisible &&
                <ImageUploadModal onSubmit={uploadImage}
                                  onClose={hideImageUploadModal}/>
            }

            {imageDeleteModalVisible &&
                <Modal title="Bestätigung"
                       closeLabel="Abbrechen"
                       onClose={hideImageDeleteModal}
                       submitLabel="Löschen"
                       onSubmit={deleteImage}
                       classNames="modal-md">
                    Bild '{ imageDescriptorToDelete.id + '.' + imageDescriptorToDelete.extension }' wirklich löschen?
                </Modal>
            }

            {collectionEditModalVisible &&
                <ImageCollectionEditModal title="Album bearbeiten"
                                          collection={collection}
                                          propertiesById={propertiesById}
                                          onClose={hideCollectionEditModal}
                                          onSubmit={updateCollection}/>
            }

            {collectionDeleteModalVisible &&
                <Modal title="Bestätigung"
                       closeLabel="Abbrechen"
                       onClose={hideCollectionDeleteModal}
                       submitLabel="Löschen"
                       onSubmit={deleteCollection}
                       classNames="modal-md">
                    Album '{ collection.name }' wirklich  löschen?
                </Modal>
            }
        </div>
    )
}

export default ImageCollection

