import * as axios from "axios";
import {googleUser} from "./utils";

export function getIdToken() {
    return googleUser().getAuthResponse().id_token;
}

export const httpClient = axios.create({
    timeout: 20000
});

httpClient.interceptors.request.use(
    config => {
        const token = getIdToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        if (!error.response || error.response.status === 401) {
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export function doRequest(config) {
    return httpClient.request(config)
}

export function doGetJson(path) {
    return doRequest({ url: path, method: 'get' });
}

export function doPutJson(path, obj) {
    return doRequest({
        url: path,
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(obj)
    });
}

export function doPutText(path, text) {
    return doRequest({
        url: path,
        method: 'put',
        headers: { 'Content-Type': 'text/plain' },
        data: text
    });
}

export function doPostJson(path, obj) {
    return doRequest({
        url: path,
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(obj)
    });
}

export function doPostFile(path, file, onUploadProgress, data = {}) {
    return doRequest({
        url: path,
        method: 'post',
        onUploadProgress: onUploadProgress,
        data: createFileUploadFormData(file, data)
    });
}

export function doPost(path) {
    return doRequest({
        url: path,
        method: 'post',
    });
}

export function doDelete(path) {
    return doRequest({
        url: path,
        method: 'delete'
    });
}

export function createFileUploadFormData(file, data) {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    return formData;
}

export function appendParams(url, params) {
    return url + (params.length > 0 ? ('?' + params.join('&')) : '');
}

export function downloadFile(url) {
    doRequest({
        url: url,
        method: 'get',
        responseType: 'blob'
    }).then(response => {
        const filename =  response.headers['content-disposition'].split('filename=')[1].slice(1, -1);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
    })
}
