import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchCurrentUser} from "./actions";

const useCurrentUser = (init = false) => {

    const dispatch = useDispatch()

    const user = useSelector(state => state.reducer.currentUser)

    useEffect(() => {
        if (init) {
            dispatch(fetchCurrentUser())
        }
    }, [init])

    return {
        currentUser: user,
        permissions: {
            admin: user && user.admin,
            editor: user && user.editor,
            voter: user && user.voter,
            accountant: user && user.accountant
        }
    }
}

export default useCurrentUser

