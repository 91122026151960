import React from "react";
import 'bootstrap-social/bootstrap-social.css'
import moment from "moment";
import {DateRangePicker} from "rsuite";

const TransactionDateRangePicker = ({value, onChange}) => {

    const now = new Date()

    const dateRanges = [
        {
            label: '30 Tage',
            value: [moment(now).add(-30, 'days').toDate(), now]
        }, {
            label: '60 Tage',
            value: [moment(now).add(-60, 'days').toDate(), now]
        }, {
            label: '90 Tage',
            value: [moment(now).add(-90, 'days').toDate(), now]
        }, {
            label: 'Aktuelles Jahr',
            value: [moment([now.getFullYear(), 0, 1]).toDate(), now]
        }, {
            label: 'Heute',
            value: [now, now]
        }
    ]

    return (
        <DateRangePicker ranges={dateRanges}
                         value={value}
                         onChange={onChange}/>
    )
}

export default TransactionDateRangePicker

