const configs = {
  dev: {
    GOOGLE_CLIENT_ID: "183702526264-0ub3426jpefs5n8pf4sa8kbqbbmn30gk.apps.googleusercontent.com"
  },
  production: {
    GOOGLE_CLIENT_ID: "298634264813-ai612rpph8goepaeuvr4s962fa2titck.apps.googleusercontent.com"
  },
};

function getProfile() {
  const hostname = window.location.hostname;
  if (hostname.indexOf("myrescore.de") >= 0) {
    return "production";
  } else {
    return "dev";
  }
}

export function getConfig() {
  return configs[getProfile()];
}

export const config = getConfig();
