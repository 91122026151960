import React, {useEffect, useState} from "react";

const ImageCollectionFilter = ({initialValue, onChange}) => {

    const [value, setValue] = useState('')

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const onValueChanged = (event) => {
        updateValue(event.target.value)
    }

    const onValueCleared = () => {
        updateValue('')
    }

    const updateValue = (value) => {
        setValue(value)
        onChange(value)
    }

    return (

        <div className="row" style={{marginBottom: "20px", padding: "0 10px"}}>
            <div className="col-xs-4">
                <input type="text"
                       className="form-control input-sm"
                       placeholder="Alben filtern"
                       value={value}
                       onChange={onValueChanged}/>
                <span id="clearImgColFilter"
                      className="glyphicon glyphicon-remove-circle"
                      onClick={onValueCleared}/>
            </div>
        </div>
    )
}

export default ImageCollectionFilter
