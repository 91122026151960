import React, {useEffect, useState} from "react";
import CrudTable from "../common/components/CrudTable";
import useCurrentUser from "../shell/useCurrentUser";
import * as API from "../common/api";


const BankAccounts = () => {

    const [bankAccounts, setBankAccounts] = useState([])

    const { permissions } = useCurrentUser()

    const columns = [
        { key: 'id',             label: '#',    type: 'number', editable: false },
        { key: 'name',           label: 'Name', type: 'text',   editable: permissions.admin },
        { key: 'iban',           label: 'IBAN', type: 'text',   editable: permissions.admin },
        { key: 'bic',            label: 'BIC',  type: 'text',   editable: permissions.admin },
        { key: 'statementType',  label: 'Typ',  type: 'text',   editable: permissions.admin },
    ]

    useEffect(() => {
        fetchBankAccounts()
    }, [])

    const fetchBankAccounts = () => {
        return API.fetchBankAccounts().then(response => setBankAccounts(response.data));
    }

    const createBankAccount = (loan) => {
        return API.createBankAccount(loan).then(fetchBankAccounts).catch(console.log)
    }

    const updateBankAccount = (loan) => {
        return API.updateBankAccount(loan).then(fetchBankAccounts).catch(console.log)
    }

    const deleteBankAccount = (loan) => {
        return API.deleteBankAccount(loan.id).then(fetchBankAccounts).catch(console.log)
    }

    return (
        <CrudTable rows={bankAccounts}
                   columns={columns}
                   onRowCreated={createBankAccount}
                   onRowUpdated={updateBankAccount}
                   onRowDeleted={deleteBankAccount}/>
    )
}

export default BankAccounts
