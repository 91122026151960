import React, {useEffect, useState} from "react";
import * as API from "../common/api";
import NavLink from "../common/components/NavLink";
import Interweave from "interweave";
import {formatDate} from "../common/utils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const HitGroup = (props) => (
    <div>
        <div className="text-muted search-hit-header">{props.label}</div>
        <ul className="list-unstyled">
            {props.children}
        </ul>
    </div>
)

const MatchedFields = ({result}) => (
    <HitGroup label="Objektfelder">
        {result.matchedFields.map((field, idx) => (
            <li key={`sri-field-${idx}`} className="search-li">
                <span className='text-success' style={{marginRight: "5px"}}>{field.name}:</span>
                <span className="text-muted"><Interweave content={field.value}/></span>
            </li>
        ))}
    </HitGroup>
)

const MatchedRentalAgreements = ({result}) => (
    <HitGroup label="Mietverträge">
        {result.matchedRentalAgreements.map((rentalAgreement, idx) => (
            <li key={`sri-ra-${idx}`} className="search-li">
                {rentalAgreement.counterParty &&
                <span className="text-success"><Interweave content={rentalAgreement.counterParty}/></span>
                }
                {rentalAgreement.counterPartyAddress &&
                <span className="text-success"><Interweave content={` (${rentalAgreement.counterPartyAddress})`}/></span>
                }
            </li>
        ))}
    </HitGroup>
)

const MatchedContacts = ({result}) => (
    <HitGroup label="Kontakte">
        {result.matchedContacts.map((contact, idx) => (
            <li key={`sri-ra-${idx}`} className="search-li">
                {contact.name &&
                <span className="text-success"><Interweave content={contact.name}/></span>
                }
                {contact.address &&
                <span className="text-success"><Interweave content={` (${contact.address})`}/></span>
                }
            </li>
        ))}
    </HitGroup>
)

const MatchedComments = ({result}) => (
    <HitGroup label="Kommentare">
        {result.matchedComments.map((comment, idx) => (
            <li key={`sri-comment-${idx}`} className="search-li">
                <span className="text-success"><Interweave content={comment.userName}/></span>
                <span className="text-success" style={{marginRight: "5px"}}> am {formatDate(comment.updateTime)}:</span>
                <span className="text-muted"><Interweave content={comment.text}/></span>
            </li>
        ))}
    </HitGroup>
)

const MatchedAttachments = ({result}) => (
    <HitGroup label="Anhänge">
        {result.matchedAttachments.map((attachment, idx) => (
            <li key={`sri-attachment-${idx}`} className="search-li">
                <span className="text-success" style={{marginRight: "5px"}}>{attachment.name}:</span>
                {attachment.filename &&
                <span className="text-muted"><Interweave content={attachment.filename}/></span>
                }
                {attachment.text && attachment.filename &&
                <span>-<span className="text-muted"><Interweave content={attachment.text}/></span></span>
                }
                {!attachment.filename && attachment.tex &&
                <span className="text-muted"><Interweave content={attachment.text}/></span>
                }
            </li>
        ))}
    </HitGroup>
)

const MatchedChanges = ({result}) => {
    const {t} = useTranslation('changes');
    return (
        <HitGroup label="Änderungen">
            {result.matchedChanges.map((change, idx) => (
                <li key={`sri-change-${idx}`} className="search-li">
                    <span className="text-success">{t(`PROPERTY_UPDATED.${change.name}`)} (</span><span
                    className="text-success"><Interweave content={change.userName}/></span>
                    <span className="text-success"
                          style={{marginRight: "5px"}}> am {formatDate(change.changeTime)}):</span>
                    <span className="text-muted"><Interweave content={change.oldValue}/></span>
                    <span className='glyphicon glyphicon-arrow-right text-muted'
                          style={{marginLeft: "5px", marginRight: "5px"}}/>
                    <span className="text-muted"><Interweave content={change.newValue}/></span>
                </li>
            ))}
        </HitGroup>
    )
}

const PropertyResult = ({result}) => (
    <div>
        <NavLink path={`/properties/${result.propertyId}`} style={{fontSize: "16px", verticalAlign: "middle"}}>
            #{result.propertyId} - {result.plz} {result.stadt}, {result.strasseUndHausnr}
        </NavLink>
        <div className="spacer spacer5"/>
        {result.matchedFields && result.matchedFields.length > 0 &&
            <MatchedFields result={result}/>
        }
        {result.matchedRentalAgreements && result.matchedRentalAgreements.length > 0 &&
            <MatchedRentalAgreements result={result}/>
        }
        {result.matchedContacts && result.matchedContacts.length > 0 &&
            <MatchedContacts result={result}/>
        }
        {result.matchedComments && result.matchedComments.length > 0 &&
            <MatchedComments result={result}/>
        }
        {result.matchedAttachments && result.matchedAttachments.length > 0 &&
            <MatchedAttachments result={result}/>
        }
        {result.matchedChanges && result.matchedChanges.length > 0 &&
            <MatchedChanges result={result}/>
        }
    </div>
)

const SearchResult = ({result}) => (
    <div style={{marginBottom: "15px"}}>
        {result.type === 'PROPERTY' &&
            <PropertyResult result={result}/>
        }
    </div>
)

const SearchContainer = () => {

    const [state, setState] = useState({results: [], latency: 0, searching: false})

    const { results, latency, searching } = state

    const searchPhrase = useSelector(state => state.reducer.searchPhrase)

    useEffect(() => {
        search()
    }, [searchPhrase])

    const search = () => {
        const start = new Date().getTime();
        setState({
            results: [],
            latency: 0,
            searching: true
        })
        return API.search(searchPhrase).then(response => {
            setState({
                results: response.data,
                latency: new Date().getTime() - start,
                searching: false
            })
        }).catch(console.log)
    }

    return (
        <div>
            <div className="context-menu hidden-print">
                {searching ? (
                    <ol className="breadcrumb display-inline">
                        <li>Suche läuft...</li>
                    </ol>
                ) : (
                    <ol className="breadcrumb display-inline">
                        <li>
                            {results.length === 1 ? "1 Ergebnis" : `${results.length} Ergebnisse`} ({latency / 1000} Sekunden)
                        </li>
                    </ol>
                )}
            </div>
            <div className="row">
                <div className="col-md-12">
                    {results.map((result, idx) => (
                        <SearchResult key={`search-result-${idx}`} result={result}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SearchContainer

